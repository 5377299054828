import axios from 'axios';
let middlewareUrl = process.env.REACT_APP_MIDDLEWARE_URL || 'http://localhost:7062/api';
//  let middlewareUrl = 'http://localhost:7062/api'


axios.defaults.baseURL = middlewareUrl;
const getJWT = () => {
    const jwt = window.localStorage.getItem('jwt');
    if (jwt === null) {
        return ''
    } else {
        return jwt
    }
}
let request = axios.create({
    baseUrl: middlewareUrl,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${getJWT()}`
    },
}
);
const Users = {
    createUserPassword: (data, token) => request.patch(`/users/create/password`, data, { headers: { Authorization: `Bearer ${token}` } }),
    getUsers: () => request.get(`/users`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    getUserByEmail: (email) => request.get(`/users/email/${email}`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    createUsers: (data) => request.post(`/users`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    updateUserByEmail: (email, data) => request.patch(`/users/email/${email}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    changePassword: (email, data) => request.patch(`/users/password/${email}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
}
const Creator = {
    getUserCreator: () => request.get(`/general`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    createCreator: (data) => request.post(`/general`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    updatCreator: (email, data) => request.patch(`/general/email/${email}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),

}
const Auth = {
    login: (email, data) => request.post(`/auth/login/${email}`, data)
}
const Companies = {
    getCompanies: () => request.get(`/companies`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    getACompanyByID: (id) => request.get(`/companies/id/${id}`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    getCompaniesByPerms: (email) => request.get(`/companies/email/${email}`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    getCompaniesByName: (name) => request.get(`/companies/name/${name}`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    createCompanies: (data) => request.post(`/companies`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    getCompaniesSheetData: (data) => request.put(`/getcompaniesdata`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    updateCompaniesByName: (name) => request.patch(`/companies/name/${name}`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    updateCompByID:(id, data) => request.patch(`/prophet/id/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    updateCompDocsByName: (name, data) => request.patch(`/companies/document/${name}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    updateCompaniesById: (id, data) => request.patch(`/companies/id/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    uploadActualCsvByID: (id, data) => request.patch(`/companies/bulkActuals/id/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    uploadTargetCsvByID: (id, data) => request.patch(`/companies/bulkTargets/id/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
}
const jobs = {
    getJob: () => request.get(`/job`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    getJobByEmail: (email) => request.get(`/job/company/${email}`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    createJob: (data) => request.post(`/job`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    updateJobById: (id, data) => request.patch(`/job/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    updateJobPendingById: (id, data) => request.patch(`/jobPending/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    taskComplete: (id, data) => request.patch(`/taskcomplete/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    departmentDecision: (id, data) => request.patch(`/departmentdecision/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    declineCreds: (id, data) => request.patch(`/acceptdeclinecreds/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),


    clientAcceptCredits: (id, data) => request.patch(`/clientacceptedcreds/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    clientDeclineCredits: (id, data) => request.patch(`/clientdeclinedcreds/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    departmentAccept: (id, data) => request.patch(`/departementaccepttask/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    departmentDecline: (id, data) => request.patch(`/departmentdeclinetask/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    departmentCompleteTask: (id, data) => request.patch(`/departmentcompletetask/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    updateTaskById: (id, data) => request.patch(`/job/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    createMeeting: (data) => request.post(`/meetingtask`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
}
const Courses = {
    getCourse: () => request.get(`/course`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    getCourseByID: (id) => request.get(`/course/id/${id}`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    createCourse: (data) => request.post(`/course`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    updateCourseByID: (id, data) => request.patch(`/course/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
}
const OkrSessions = {
    getOkrSessions: () => request.get(`/OkrSessions`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    getOKrSessionByID: (id) => request.get(`/OkrSessions/id/${id}`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    createOkrSession: (data) => request.post(`/OkrSessions`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    updateOkrSessionsById: (id, data) => request.patch(`/OkrSessions/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
}
const Aions = {
    getAions: () => request.get(`/aions`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    getAionsByID: (id) => request.get(`/aions/id/${id}`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    createAions: (data) => request.post(`/aions`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    createAionsData: (data) => request.post(`/aionsdata`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    patchAionsByID: (id, data) => request.patch(`/aions/id/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    deleteAionsByID: (id) => request.delete(`/aions/${id}`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    aionsDeleteAll: () => request.delete(`/aionsdeleteall`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
}
const SpreadSheet = {
    getSheetData: () => request.get(`/income`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    getAionsAV: () => request.get(`/aionsventures`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } })
}
const Client = {
    getClient: () => request.get(`/clients`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    createClient: (data) => request.post(`/clients`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    getClientByID: (id) => request.get(`/clients/id/${id}`, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } }),
    patchClientByID: (id, data) => request.patch(`/clients/id/${id}`, data, { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } })
}
const Xero = {
    getXeroOath: () => request.get('/xero', { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } })
}
const HubSpot = {
    getDeals: () => request.get('/hubspot', { headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` } })
}
export {
    Users,
    Auth,
    Companies,
    Courses,
    jobs,
    Creator,
    OkrSessions,
    Aions,
    SpreadSheet,
    Client,
    Xero,
    HubSpot
}