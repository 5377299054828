import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { Textbox, CustomSelect, CashflowGraph, RunwayGauge, BurnRateGraph, Calender } from '../../../components';
import CloseArchive from '../../../assets/images/closeArchive.svg';
import Filter from '../../../assets/images/Filter.svg';
import FilterDown from '../../../assets/images/FilterDown.svg';
import LeftArrow from '../../../assets/images/leftArrow.svg';
import RightArrow from '../../../assets/images/rightArrow.svg';
import AddImg from '../../../assets/images/add.png';
import DeleteSVG from '../../../assets/images/DeleteSVG.svg';
import EditSVG from '../../../assets/images/EditSVG.svg';
import CloseCard from '../../../assets/images/CloseTask.svg';

const Reports = observer(({ compId, back }) => {
    const store = useStore();
    const navigate = useNavigate();
    const [, updateState] = useState();
    const graphSectionRef = useRef();
    const overViewSectionref = useRef()
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [overViewDimensions, setOverviewDimensions] = useState({ width: 0, height: 0 })
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [FilterComps, setFilterComps] = useState('A-Z');
    const [Company, setCompany] = useState({});
    const [Filter, setFilter] = useState('Month');
    const [Onset, setOnset] = useState(0);
    const [AddMonth, setMonth] = useState(false);
    const [Actuals, setActuals] = useState([]);
    const [OnHover, setOnHover] = useState(false);
    const [LegendsFilter, setLegendsFilter] = useState([]);
    const [SelectedFilter, setSelectedFilter] = useState({
        CurrentMonth: {},
        PreviousMonth: {}
    });
    const [TabSelected, setTabSelected] = useState('Forecast');
    const [MetricsData, setMetricsData] = useState([]);
    const [CredsDate, setCredsDate] = useState(null);
    const [SelectedMetric, setSelectedMetric] = useState({
        NewMetric: {},
        OldMetric: {}
    });
    const [IsHovered, setIsHovered] = useState(false);
    const [UpdateMetric, setUpdateMetric] = useState(false);
    const [ViewMetrics, setViewMetrics] = useState(false)
    const excludedKeys = ['Type', 'Income', 'Costs', 'Expenses', 'Cashflow', 'Burnrate', 'ForecastBurnrate', 'ForecastCashflow', 'ForecastExpenses', 'ForecastIncome'];
    const [runwayData, setRunway] = useState(0);
    const updateRunway = (newData) => {
        setRunway(newData);
    };
    const [burnRate, setBurnRate] = useState(0);
    const [AddNewMetric, setAddNewMetric] = useState(false);
    const [chartData, setData] = useState([]);
    const updateData = (newData) => {
        setData(newData);
    };
    const [IsLabelOn, setIsLabelOn] = useState(false);
    const [isToggled, setIsToggled] = useState(false);
    // Step 1: Initialize the vertical chart data state
    const [verticalChartData, setVerticalChartData] = useState([]);

    // Step 2: Function to update the chart data state
    const updateVerticalChartData = (newData) => {
        setVerticalChartData(newData);
    };
    const [metrics, setMetrics] = useState({
        Burnrate: {
            Burn_Rate: [
                { Category: '', Amount: 0 },
            ],
        },

        Costs: {
            Cost_of_Sales: [
                { Category: '', Amount: 0 },
            ],
        },
        Expenses: {
            Operating_Expenses: [
                { Category: '', Amount: 0 },
            ],
        },
        Income: {
            Income: [
                { Category: '', Amount: 0 },
            ],
        },
        Cashflow: {
            Cash_Flow: [
                { Category: '', Amount: 0 },
            ],
        },
        Date: new Date(),
        Type: "",
    });
    useEffect(() => {
        getComp();
        const updateDimensions = () => {
            if (graphSectionRef.current) {
                setDimensions({
                    width: graphSectionRef.current.offsetWidth,
                    height: graphSectionRef.current.offsetHeight,
                });
            }
            if (overViewSectionref.current) {
                setOverviewDimensions({
                    width: overViewSectionref.current.offsetWidth,
                    height: overViewSectionref.current.offsetHeight,
                });
            }
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);
    const getComp = async () => {
        store.setLoading(true);
        let comp = await store.getCompanyByID(compId);
        if (typeof comp === "string" || !comp) {
            setCompany({});
            store.setLoading(false);
        }
        let ret = await store.getCompanyByID(compId);
        setCompany(ret);
        let acts = ret.Values;
        setMetricsData(acts)
        setActuals(acts);
        let finalData = [];
        let actuals = [];
        let forecasts = [];
        for (let i = 0; i < acts.length; i++) {
            let tempActs = acts[i];
            if (tempActs.Type === 'Forecast') {
                forecasts.push(tempActs);
            } else if (tempActs.Type === 'Actual') {
                actuals.push(tempActs);
            }
        }
        const currentDate = new Date();
        const currMonthObj = actuals.map(item => ({ ...item, Date: new Date(item.Date) })).sort((a, b) => b.Date - a.Date)[0];
        let latestDate = new Date(currMonthObj.Date);
        let currCashflow = 0;
        if (currMonthObj && currMonthObj.Cashflow) {
            currCashflow = Object.values(currMonthObj.Cashflow).reduce((acc, arr) => {
                return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
            }, 0);
        };
        let currBurnrate = 0;
        if (currMonthObj && currMonthObj.Burnrate) {
            currBurnrate = Object.values(currMonthObj.Burnrate).reduce((acc, arr) => {
                return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
            }, 0);
        };
        let runway = calculateRunway(currCashflow, currBurnrate);
        if (runway > 0) {
            setRunway(Number(runway));
        } else {
            setRunway(0);
        }
        const endOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        const filteredActuals = actuals.filter(actual => {
            const actualDate = new Date(actual.Date);
            return actualDate <= endOfPreviousMonth;
        });
        const startOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const endOfThirdMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 0);
        const startOfFollowingMonth = new Date(latestDate.getFullYear(), latestDate.getMonth() + 1, 1);
        const filteredForecasts = forecasts.filter(forecast => {
            const forecastDate = new Date(forecast.Date);
            return forecastDate >= startOfFollowingMonth;
        });
        for (let i = 0; i < actuals.length; i++) {
            let item = actuals[i];
            let income = item.Income;
            let expenses = item.Expenses;
            let cashflow = item.Cashflow;
            let burnrate = item.Burnrate;
            let type = item.Type;
            let date = item.Date;
            let totalIncome = 0;
            if (income) {
                totalIncome = Object.values(income).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalExpenses = 0;
            if (expenses) {
                totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalCashflow = 0;
            if (cashflow) {
                totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalBurnrate = 0;
            if (burnrate) {
                totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let finalObj = {
                Income: totalIncome,
                Expenses: totalExpenses,
                Cashflow: totalCashflow,
                Burnrate: totalBurnrate,
                Type: type,
                date: new Date(date)
            }
            finalData.push(finalObj);
        }
        forecasts.forEach((item) => {
            let income = item.Income;
            let expenses = item.Expenses;
            let cashflow = item.Cashflow;
            let burnrate = item.Burnrate;
            let type = item.Type;
            let date = item.Date;
            let totalIncome = 0;
            if (income) {
                totalIncome = Object.values(income).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalExpenses = 0;
            if (expenses) {
                totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalCashflow = 0;
            if (cashflow) {
                totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalBurnrate = 0;
            if (burnrate) {
                totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let finalObj = {
                Income: totalIncome,
                Expenses: totalExpenses,
                Cashflow: totalCashflow,
                Burnrate: totalBurnrate,
                Type: type,
                date: new Date(date)
            }
            finalData.push(finalObj);
        });
        // Vertical bar chart calculations.

        let vertdata = []

        const parseDate = (dateString) => new Date(dateString);
        const actualsArr = actuals.filter(item => parseDate(item.Date) <= latestDate).sort((a, b) => parseDate(b.Date) - parseDate(a.Date)).slice(0, 4);
        const nextMonth = new Date(latestDate);
        nextMonth.setMonth(latestDate.getMonth() + 1);
        const forecastArr = forecasts.filter(item => parseDate(item.Date) >= nextMonth).sort((a, b) => parseDate(a.Date) - parseDate(b.Date)).slice(0, 4);
        for (let i = 0; i < actualsArr.length; i++) {
            let item = actualsArr[i];
            let income = item.Income;
            let expenses = item.Expenses;
            let cashflow = item.Cashflow;
            let burnrate = item.Burnrate;

            let type = item.Type;
            let date = item.Date;
            let totalIncome = 0;
            if (income) {
                totalIncome = Object.values(income).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalExpenses = 0;
            if (expenses) {
                totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalCashflow = 0;
            if (cashflow) {
                totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalBurnrate = 0;
            if (burnrate) {
                totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let finalObj = {
                Income: totalIncome,
                Expenses: totalExpenses,
                Cashflow: totalCashflow,
                Burnrate: totalBurnrate,
                Type: type,
                date: new Date(date)
            }
            vertdata.push(finalObj);
        }
        forecastArr.forEach((item) => {
            let income = item.Income;
            let expenses = item.Expenses;
            let cashflow = item.Cashflow;
            let burnrate = item.Burnrate;
            let type = item.Type;
            let date = item.Date;
            let totalIncome = 0;
            if (income) {
                totalIncome = Object.values(income).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalExpenses = 0;
            if (expenses) {
                totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalCashflow = 0;
            if (cashflow) {
                totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalBurnrate = 0;
            if (burnrate) {
                totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let finalObj = {
                Income: totalIncome,
                Expenses: totalExpenses,
                Cashflow: totalCashflow,
                Burnrate: totalBurnrate,
                Type: type,
                date: new Date(date)
            }
            vertdata.push(finalObj);
        });

        setVerticalChartData(vertdata);
        setData(finalData);

        let onset = 0;
        handleMonthlyData(acts, onset);
        store.setLoading(false);
        forceUpdate();
    };
    const syncSheetData = async () => {
        store.setLoading(true);
        let comp = Company;
        try {
            if (comp.SheetID && comp.SheetID !== '' && comp.SheetID !== null) {
                let data = {
                    SheetID: comp.SheetID,
                    CompanyID: comp._id,
                    TabName: comp.TabName
                }
                if (data.SheetID === '' || data.SheetID === undefined || !data.SheetID) {
                    window.alert('No Spreadsheet data available!');
                    return;
                }
                const userConfirmed = window.confirm('Are you sure you want to proceed? This will remove all your custom-added metrics and replace them with the sheet data.');
                if (userConfirmed) {
                    let syncSheetData = await store.getCompaniesSheetData(data);
                    if (syncSheetData === false) {
                        window.alert('Could not fetch spread sheet data.')
                        store.setLoading(false);
                    } else if (syncSheetData === true) {
                        store.setLoading(false);
                        getComp()
                    }
                } else {
                    store.setLoading(false);
                    return
                }
            } else {
                window.alert('No sheet data available for this company.');
                store.setLoading(false);
            }
            forceUpdate();
        } catch (e) {
            console.log(e);
            store.setLoading(false)
            return false;
        }
    }
    // Get current month from actuals
    function getObjectMatchingCurrentMonth(data) {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth(); // 0-based (0 for January, 11 for December)
        const currentYear = currentDate.getFullYear();

        return data.find(item => {
            const itemDate = new Date(item.date);
            const itemMonth = itemDate.getMonth();
            const itemYear = itemDate.getFullYear();

            return itemMonth === currentMonth && itemYear === currentYear;
        });
    };
    // Calculate Runway based on a single month
    const calculateRunway = (cashflow, burnrate) => {
        const runway = cashflow / burnrate;
        return runway.toFixed(1);
    };
    const filterActualsUpToCurrentMonth = (data) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        // Function to check if amounts are valid
        const isValidAmount = (amount) => typeof amount === 'number' && !isNaN(amount);

        // Filter 'Actual' items up to the current date
        const actualData = data.filter(item => {
            const itemMonth = item.date.getMonth();
            const itemYear = item.date.getFullYear();

            return itemYear <= currentYear &&
                (itemYear < currentYear || itemMonth <= currentMonth) &&
                item.Type === 'Actual' &&
                isValidAmount(item.Income) &&
                isValidAmount(item.Expenses) &&
                isValidAmount(item.Burnrate) &&
                isValidAmount(item.Cashflow);
        });

        // Combine the results
        return [...actualData];
    };
    const filterDataUpToCurrentMonth = (data) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        // Function to check if amounts are valid
        const isValidAmount = (amount) => typeof amount === 'number' && !isNaN(amount);

        // Filter 'Actual' items up to the current date
        const actualData = data.filter(item => {
            const itemMonth = item.date.getMonth();
            const itemYear = item.date.getFullYear();

            return itemYear <= currentYear &&
                (itemYear < currentYear || itemMonth <= currentMonth) &&
                item.Type === 'Actual' &&
                isValidAmount(item.Income) &&
                isValidAmount(item.Expenses) &&
                isValidAmount(item.Burnrate) &&
                isValidAmount(item.Cashflow);
        });

        // Filter 'Forecast' items after the current date
        const forecastData = data.filter(item => {
            const itemMonth = item.date.getMonth();
            const itemYear = item.date.getFullYear();

            return itemYear > currentYear ||
                (itemYear === currentYear && itemMonth > currentMonth) &&
                item.Type === 'Forecast';
        }).slice(0, 4);  // Take only the next 4 'Forecast' items

        // Combine the results
        return [...actualData, ...forecastData];
    };
    const convertToChartData = (data) => {
        // Extract date from the string and convert to JavaScript Date object
        const date = new Date(data.Date);

        // Helper function to sum amounts in a given category array
        function sumAmounts(categoryArray) {
            return categoryArray.reduce((sum, item) => sum + item.Amount, 0);
        }

        // Sum Income amounts
        const eCommerceIncome = sumAmounts(data.Income.ECommerce_Income);
        const otherIncome = sumAmounts(data.Income.Income);
        const totalIncome = eCommerceIncome + otherIncome;

        // Sum Costs amounts (assuming total is required, as there's only one entry each)
        const costOfSales = sumAmounts(data.Costs.Cost_of_Sales);
        const costOfGoods = sumAmounts(data.Costs.Cost_of_Goods);
        const totalCosts = costOfSales + costOfGoods;

        // Sum Cashflow amounts
        const totalCashflow = sumAmounts(data.Cashflow.Cash_Flow);

        // Sum Burnrate amounts
        const totalBurnrate = sumAmounts(data.Burnrate.Burn_Rate);

        // Sum Expenses amounts
        const operatingExpenses = sumAmounts(data.Expenses.Operating_Expenses);
        const otherExpenses = sumAmounts(data.Expenses.Other_Expenses);
        const totalExpenses = operatingExpenses + otherExpenses;

        // Construct the simplified object
        const dataObject = {
            date: date,
            Income: totalIncome,
            Expenses: totalExpenses,
            Burnrate: totalBurnrate,
            Cashflow: totalCashflow,
            Type: data.Type
        };

        // Return the resulting array with one object
        return dataObject;
    }
    const handleMoveForward = () => {
        let onset = Onset + 1;
        setOnset(Onset + 1);
        let acts = Actuals;
        if (Filter === 'Entire') {
            handleEntireData(acts, onset)
        } else if (Filter === 'Month') {
            handleMonthlyData(acts, onset)
        } else if (Filter === 'Yearly') {
            handleYearlyData(acts)
        }
        forceUpdate();
    };
    const handleMoveBackward = () => {
        let onset = Onset - 1
        setOnset(Onset - 1);
        forceUpdate();
        let acts = Actuals;
        if (Filter === 'Entire') {
            handleEntireData(acts, onset)
        } else if (Filter === 'Month') {
            handleMonthlyData(acts, onset);
        } else if (Filter === 'Yearly') {
            handleYearlyData(acts);
        }
    };
    const handleMonthlyData = (acts, onset = Onset) => {
        store.setLoading(true);
        if (acts) {
            let DataObj = {};
            let currentDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
            let currentYear = currentDate.getFullYear();
            let currentMonth = currentDate.getMonth() + onset;
            let previousMonth = currentMonth - 1;

            let currentMonthData = acts.filter((item) => {
                const objDate = new Date(item.Date).getMonth();
                const objYear = new Date(item.Date).getFullYear();
                return objDate === currentMonth && objYear === currentYear;
            });

            let prevMonthData = acts.filter((item) => {
                const objDate = new Date(item.Date).getMonth();
                const objYear = new Date(item.Date).getFullYear();
                return objDate === previousMonth && objYear === currentYear;
            });

            let CurrentMonth = {
                Type: 'Monthly',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }
            let PreviousMonth = {
                Type: 'Monthly',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }
            for (let i = 0; i < currentMonthData.length; i++) {
                let data = currentMonthData[i];
                if (data.Type === 'Forecast') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    CurrentMonth.ForecastIncome += totalIncome;
                    CurrentMonth.ForecastBurnrate += totalBurnrate;
                    CurrentMonth.ForecastCashflow += totalCashflow;
                    CurrentMonth.ForecastExpenses += totalExpenses;
                } else if (data.Type === 'Actual') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    const calculateTotal = (data, key) => {
                        return Object.values(data[key]).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    const filteredData = Object.keys(data)
                        .filter(key => !['Type', 'Date', 'Income', 'Costs', 'Expenses', 'Cashflow'].includes(key))
                        .reduce((obj, key) => {
                            obj[key] = calculateTotal(data, key);
                            return obj;
                        }, {});
                    Object.assign(CurrentMonth, filteredData);
                    CurrentMonth.Income += totalIncome;
                    CurrentMonth.Burnrate += totalBurnrate;
                    CurrentMonth.Cashflow += totalCashflow;
                    CurrentMonth.Expenses += totalExpenses;
                }
            }
            for (let i = 0; i < prevMonthData.length; i++) {
                let data = prevMonthData[i];
                if (data.Type === 'Forecast') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    PreviousMonth.ForecastIncome += totalIncome;
                    PreviousMonth.ForecastBurnrate += totalBurnrate;
                    PreviousMonth.ForecastCashflow += totalCashflow;
                    PreviousMonth.ForecastExpenses += totalExpenses;
                } else if (data.Type === 'Actual') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    PreviousMonth.Income += totalIncome;
                    PreviousMonth.Burnrate += totalBurnrate;
                    PreviousMonth.Cashflow += totalCashflow;
                    PreviousMonth.Expenses += totalExpenses;
                }
            }
            DataObj.CurrentMonth = CurrentMonth;
            DataObj.PreviousMonth = PreviousMonth;
            DataObj.Date = new Date(currentYear, currentMonth);
            DataObj.PreviousDate = new Date(currentYear, currentMonth - 1)
            setSelectedFilter(DataObj);
            store.setLoading(false);
        } else {
            window.alert('No Actual available.');
            store.setLoading(false);
        }
        forceUpdate();
    };
    const handleYearlyData = (acts, onset = Onset) => {
        store.setLoading(true);
        if (acts) {
            let DataObj = {};
            let currentDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
            let currentYear = currentDate.getFullYear() + onset;
            let currentMonthDate = currentDate.getMonth();
            let currentMonthData = acts.filter((item) => {
                const objYear = new Date(item.Date).getFullYear();
                return objYear === currentYear;
            });
            let prevMonthData = acts.filter((item) => {
                const objYear = new Date(item.Date).getFullYear();
                return objYear === currentYear - 1;
            });
            let CurrentMonth = {
                Type: 'Yearly',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }
            let PreviousMonth = {
                Type: 'Yearly',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }
            let UpdatedArr = [];
            for (let i = 0; i < currentMonthData.length; i++) {
                let data = currentMonthData[i];
                if (data.Type === 'Forecast') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    CurrentMonth.ForecastIncome += totalIncome;
                    CurrentMonth.ForecastBurnrate += totalBurnrate;
                    CurrentMonth.ForecastCashflow += totalCashflow;
                    CurrentMonth.ForecastExpenses += totalExpenses;
                } else if (data.Type === 'Actual') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    const { Type, Date, Income, Costs, Expenses, Cashflow, Burnrate, ...filteredData } = currentMonthData[i];
                    UpdatedArr.push(filteredData);
                    CurrentMonth.Income += totalIncome;
                    CurrentMonth.Burnrate += totalBurnrate;
                    CurrentMonth.Cashflow += totalCashflow;
                    CurrentMonth.Expenses += totalExpenses;
                }
            }
            for (let i = 0; i < prevMonthData.length; i++) {
                let data = prevMonthData[i];
                if (data.Type === 'Forecast') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    PreviousMonth.ForecastIncome += totalIncome;
                    PreviousMonth.ForecastBurnrate += totalBurnrate;
                    PreviousMonth.ForecastCashflow += totalCashflow;
                    PreviousMonth.ForecastExpenses += totalExpenses;
                } else if (data.Type === 'Actual') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    PreviousMonth.Income += totalIncome;
                    PreviousMonth.Burnrate += totalBurnrate;
                    PreviousMonth.Cashflow += totalCashflow;
                    PreviousMonth.Expenses += totalExpenses;
                }
            }
            UpdatedArr.forEach((data) => {
                Object.keys(data).forEach(category => {
                    if (!CurrentMonth[category]) {
                        CurrentMonth[category] = 0;
                    }
                    let categoryData = data[category];
                    Object.keys(categoryData).forEach(subCategory => {
                        let subCategoryArray = categoryData[subCategory];
                        subCategoryArray.forEach(item => {
                            if (item && typeof item === 'object' && item.hasOwnProperty('Amount')) {
                                CurrentMonth[category] += item.Amount;
                            }
                        });
                    });
                });
            });
            DataObj.CurrentMonth = CurrentMonth;
            DataObj.PreviousMonth = PreviousMonth;
            DataObj.Date = new Date(currentYear, currentMonthDate);
            DataObj.PreviousDate = new Date(currentYear, currentMonthDate - 1)
            setSelectedFilter(DataObj);
            store.setLoading(false);
        } else {
            window.alert('No Actuals Avavilable');
            store.setLoading(false);
        }
        forceUpdate();
    };
    const handleEntireData = (acts) => {
        store.setLoading(true);
        if (acts) {
            let DataObj = {};
            let data = acts;
            let CurrentMonth = {
                Type: 'Entire',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }
            let PreviousMonth = {
                Type: 'Entire',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }
            let UpdatedArr = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].Type === 'Forecast') {
                    let income = data[i].Income;
                    let expenses = data[i].Expenses;
                    let burnrate = data[i].Burnrate;
                    let cashflow = data[i].Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    CurrentMonth.ForecastIncome += totalIncome;
                    CurrentMonth.ForecastBurnrate += totalBurnrate;
                    CurrentMonth.ForecastCashflow += totalCashflow;
                    CurrentMonth.ForecastExpenses += totalExpenses;
                } else if (data[i].Type === 'Actual') {
                    let income = data[i].Income;
                    let expenses = data[i].Expenses;
                    let burnrate = data[i].Burnrate;
                    let cashflow = data[i].Cashflow;
                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    const { Type, Date, Income, Costs, Expenses, Cashflow, Burnrate, ...filteredData } = data[i];
                    UpdatedArr.push(filteredData);

                    CurrentMonth.Income += totalIncome;
                    CurrentMonth.Burnrate += totalBurnrate;
                    CurrentMonth.Cashflow += totalCashflow;
                    CurrentMonth.Expenses += totalExpenses;
                }
            }
            UpdatedArr.forEach((data) => {
                Object.keys(data).forEach(category => {
                    if (!CurrentMonth[category]) {
                        CurrentMonth[category] = 0;
                    }
                    let categoryData = data[category];
                    Object.keys(categoryData).forEach(subCategory => {
                        let subCategoryArray = categoryData[subCategory];
                        subCategoryArray.forEach(item => {
                            if (item && typeof item === 'object' && item.hasOwnProperty('Amount')) {
                                CurrentMonth[category] += item.Amount;
                            }
                        });
                    });
                });
            });
            DataObj.CurrentMonth = CurrentMonth;
            DataObj.PreviousMonth = PreviousMonth;
            setSelectedFilter(DataObj);
            store.setLoading(false);
        } else {
            window.alert('No Actuals Available.');
            store.setLoading(false);
        }
        forceUpdate();
    };
    const formatDate = (date) => {
        if (date === undefined) {
            date = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
        }
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const year = date.getFullYear();
        const month = date.getMonth();

        if (Filter === 'Month') {
            return `${monthNames[month]} ${year}`;
        } else if (Filter === 'Yearly') {
            return year.toString();
        } else {
            return '';
        }
    };
    const openHover = () => {
        setOnHover(true);
        forceUpdate();
    };
    const closeHover = () => {
        setOnHover(false);
        forceUpdate();
    };
    const changeFilter = (e) => {
        let filter = e.currentTarget.id;
        let acts = Actuals;
        let onset = 0;
        setOnset(onset);
        if (filter === 'Entire') {
            setFilter(filter);
            handleEntireData(acts);
        } else if (filter === 'Month') {
            setFilter(filter);
            handleMonthlyData(acts, onset);
        } else if (filter === 'Yearly') {
            setFilter(filter);
            handleYearlyData(acts, onset);
        }
    };
    const backClicked = () => {
        back('Reports');
        forceUpdate();
    }
    const handleToggle = () => {
        setIsToggled(!isToggled);
    };
    const openLabelClicked = () => {
        setIsLabelOn(true);
        forceUpdate();
    };
    const closeLabelClicked = () => {
        setIsLabelOn(false);
        forceUpdate();
    };
    const legendClicked = (e) => {
        let legends = [...LegendsFilter];
        let id = e.currentTarget.id;

        if (legends.includes(id)) {
            legends = legends.filter((legendId) => legendId !== id);
        } else {
            legends.push(id);
        }
        setLegendsFilter(legends);
        forceUpdate();
    };
    const viewMetricsClicked = (e) => {
        if (e.currentTarget.id === 'Report') {
            setViewMetrics(false);
        }
        else if (e.currentTarget.id === 'Metrics') {
            setViewMetrics(true);
        }
        forceUpdate()
    };
    const updateMetricClicked = async () => {
        store.setLoading(true);
        try {
            let values = SelectedMetric;
            let newObj = {
                Burnrate: {
                    Burn_Rate: [
                        { Category: '', Amount: values.NewMetric.Burnrate },
                    ],
                },

                Costs: {
                    Cost_of_Sales: [
                        { Category: '', Amount: 0 },
                    ],
                },
                Expenses: {
                    Operating_Expenses: [
                        { Category: '', Amount: values.NewMetric.Expenses },
                    ],
                },
                Income: {
                    Income: [
                        { Category: '', Amount: values.NewMetric.Income },
                    ],
                },
                Cashflow: {
                    Cash_Flow: [
                        { Category: '', Amount: values.NewMetric.Cashflow },
                    ],
                },
                Date: new Date(values.NewMetric.Date),
                Type: values.NewMetric.Type,
            }
            const updatedArray = MetricsData.map(item =>
                JSON.stringify(item) === JSON.stringify(values.OldMetric) ? newObj : item
            );
            let data = {
                Values: updatedArray
            }
            let update = await store.updateCompanyById(Company._id, data);
            if (!update) {
                alert('Error updating metric.');
                store.setLoading(false);
                return;
            }
            setMetricsData(updatedArray);
            setUpdateMetric(false);
            forceUpdate();
            store.setLoading(false);
        } catch (e) {
            console.error('Error updating metric', e);
            alert('Error updating metrics.')
            store.setLoading(false);
            return
        }
    }
    const typeChange = (e) => {
        let item = metrics;
        item.Type = e.target.id;
        setMetrics(item);
        forceUpdate();
    };
    const closeAddMetric = () => {
        setAddNewMetric(false);
        setUpdateMetric(false);
        forceUpdate();
    };
    const handleHoverStart = () => {
        setIsHovered(true);
        forceUpdate();
    };
    const handleHoverEnd = () => {
        setIsHovered(false);
        forceUpdate();
    };
    const addMonth = () => {
        setMonth(true);
        forceUpdate();
    }
    const closeMonth = () => {
        setMonth(false);
        forceUpdate();
    }
    const handleChange = (e, category) => {
        const value = parseFloat(e.target.value) || 0;
        setMetrics(prevMetrics => ({
            ...prevMetrics,
            [category]: {
                ...prevMetrics[category],
                [Object.keys(prevMetrics[category])[0]]: prevMetrics[category][Object.keys(prevMetrics[category])[0]].map(item => ({
                    ...item,
                    Amount: value,
                }))
            }
        }));
        calculateBurnRate();
        forceUpdate();
    };
    const getDate = (date) => {
        setCredsDate(date);
        forceUpdate();
    };
    const saveMeterics = async () => {
        store.setLoading(true);
        try {
            let compId = Company._id;
            let data = Company;
            let newOn = metrics;
            let values = data.Values;

            if (newOn.Type === 'Forecast') {
                let date = convertToISODate(CredsDate)
                newOn.Date = date;
            } else if (newOn.Type === 'Actual') {
                let actDate = new Date()
                let finalDate = convertToISOFromActDate(actDate)
                newOn.Date = finalDate;
            }
            if (newOn.Type === '') {
                alert('Type required.');
                return;
            }
            let found = false;

            values.forEach((item, index) => {
                const itemDate = new Date(item.Date);
                const newOnDate = new Date(newOn.Date);

                if (item.Type === 'Actual' && itemDate.getFullYear() === newOnDate.getFullYear() && itemDate.getMonth() === newOnDate.getMonth()) {
                    values.splice(index, 1);
                    values.push(newOn);
                    found = true;
                }
            });
            if (!found) {
                values.push(newOn);
            }
            data.Values = values;
            let newData = {
                Values: values
            }
            let update = await store.updateCompanyById(compId, newData);
            if (!update) {
                window.alert('Error saving metrics.');
                store.setLoading(false);
                return;
            }
            window.alert('Metrics saved successfully.');
            setAddNewMetric(false);
            setUpdateMetric(false);
            MetricsData.push(newOn);
            forceUpdate();
            store.setLoading(false);
        } catch (e) {
            console.error(e);
            store.setLoading(false);
            return
        }
        forceUpdate();
    };
    function convertToISOFromActDate(actDateString) {
        const dateObject = new Date(actDateString);
        const isoDateString = dateObject.toISOString();
        return isoDateString;
    }
    function convertToISODate(dateString) {
        const [monthName, year] = dateString.split(' ');
        const month = new Date(`${monthName} 1, ${year}`).getMonth() + 1;
        const isoDateString = new Date(`${year}-${month.toString().padStart(2, '0')}-01T22:00:00.000Z`).toISOString();
        return isoDateString;
    }
    function formatDateToMonthYear(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long' };
        return date.toLocaleDateString('en-US', options);
    };
    const calculateBurnRate = () => {
        const expenses = metrics.Expenses.Operating_Expenses.reduce((acc, item) => acc + item.Amount, 0);
        const income = metrics.Income.Income.reduce((acc, item) => acc + item.Amount, 0);
        const cashflow = metrics.Cashflow.Cash_Flow.reduce((acc, item) => acc + item.Amount, 0);

        const burnRateValue = cashflow !== 0 ? (expenses - income) / cashflow : 0;
        setMetrics(prevMetrics => ({
            ...prevMetrics,
            Burnrate: {
                Burn_Rate: prevMetrics.Burnrate.Burn_Rate.map(item => ({
                    ...item,
                    Amount: burnRateValue,
                }))
            }
        }));
        setBurnRate(burnRateValue);
        forceUpdate();
    };
    const handleUpdateMetric = (item) => {
        let totalIncome = sumAllValues(item.Income);
        let totalExpenses = sumAllValues(item.Expenses);
        let totalCashflow = sumAllValues(item.Cashflow)
        let totalBurnRate = sumAllValues(item.Burnrate)
        let date = item.Date;
        let type = item.Type;
        let newObj = {
            Income: totalIncome,
            Expenses: totalExpenses,
            Cashflow: totalCashflow,
            Burnrate: totalBurnRate,
            Date: date,
            Type: type,
        }
        let data = {
            OldMetric: item,
            NewMetric: newObj
        }
        setSelectedMetric(data);
        setUpdateMetric(true);
        forceUpdate();
    };
    const handleDeleteMetric = async (item) => {
        try {
            let comfirmed = window.confirm(`Are you sure you want to delete the ${item.Type} metric from ${formatDateToMonthYear(item.Date)}?`);
            store.setLoading(true);
            if (!comfirmed) {
                store.setLoading(false);
                return
            } else {
                let values = MetricsData;
                const index = values.findIndex((metric) =>
                    JSON.stringify(metric) === JSON.stringify(item)
                );
                if (index !== -1) {
                    values.splice(index, 1);
                }

                let compId = Company._id;
                let data = {
                    Values: values
                };
                let update = await store.updateCompanyById(compId, data);
                if (!update) {
                    alert('Error deleting metric.');
                    store.setLoading(false);
                    return;
                }
                setMetricsData(values);
                forceUpdate();
                store.setLoading(false);
            }
        } catch (error) {
            console.error('Error deleting metric');
            store.setLoading(false);
            return;
        }
    };
    function sumAllValues(values) {
        let total = 0;

        for (const key in values) {
            if (Array.isArray(values[key])) {
                total += values[key].reduce((sum, item) => sum + (item.Amount || 0), 0);
            }
        }

        return total;
    }
    const handleUpdateMetricValues = (e) => {
        let id = e.currentTarget.id;
        let newObj = SelectedMetric.NewMetric;
        if (id === 'Income') {
            newObj.Income = Number(e.target.value)
        }
        if (id === 'Expenses') {
            newObj.Expenses = Number(e.target.value)
        }
        if (id === 'Burnrate') {
            newObj.Burnrate = Number(e.target.value)
        }
        if (id === 'Cashflow') {
            newObj.Cashflow = Number(e.target.value)
        }
        let data = {
            OldMetric: SelectedMetric.OldMetric,
            NewMetric: newObj
        }
        setSelectedMetric(data);
        forceUpdate();
    };
    const addNewMetricClicked = () => {
        setAddNewMetric(true);
        forceUpdate();
    };
    const changeTabSelected = (e) => {
        setTabSelected(e.currentTarget.id);
        forceUpdate();
    }
    const income = SelectedFilter.CurrentMonth.Income;
    const expenses = SelectedFilter.CurrentMonth.Expenses;
    const profit = income - expenses;
    return (
        <div className='OverViewPage'>
            <div className='income-loss-container'>
                <div style={{ width: '30%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img className='back-button' style={{ width: '5%', marginRight: '3%', cursor: 'pointer' }} src={CloseArchive} alt='' onClick={backClicked} />
                    <div className='ProfitLossContrainer' style={{ backgroundColor: profit > 0 ? '#213038' : '#A62626' }} onMouseEnter={openLabelClicked} onMouseLeave={closeLabelClicked}>
                        <p>{profit >= 0 ? 'R ' : '-R '} {Math.abs(profit).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                        {(IsLabelOn) ? <p className='labelPlaceholder'>{profit > 0 ? 'Profit' : 'Loss'}</p> : null}
                    </div>
                </div>
                {(ViewMetrics) ?
                    <button className='viewReportMetricsBtn' id='Report' onClick={viewMetricsClicked}>View Report</button> : <button className='viewReportMetricsBtn' id='Metrics' onClick={viewMetricsClicked}>View Metrics</button>}

                <p style={{ fontWeight: '700', fontSize: '25px' }}>{Company.Name}</p>
                <div className='period-section' onMouseEnter={openHover} onMouseLeave={closeHover}>
                    <p>{Filter}</p>
                    {(OnHover) ?
                        <>
                            <div className='period-pointer' />
                            <div className='period-container'>
                                <div className='period-container-options' id='Month' style={{ backgroundColor: Filter === 'Month' ? '#D5922D' : '' }} onClick={changeFilter}>Month</div>
                                <div className='period-container-options' id='Yearly' style={{ backgroundColor: Filter === 'Yearly' ? '#D5922D' : '' }} onClick={changeFilter}>Yearly</div>
                                <div className='period-container-options' id='Entire' style={{ backgroundColor: Filter === 'Entire' ? '#D5922D' : '' }} onClick={changeFilter}>Entire</div>
                            </div>
                        </> : null}
                </div>
            </div>
            {(ViewMetrics) ?
                <div className='viewMetricsScreen'>
                    <div className='tabSection'>
                        <div className='forecastActSect'>
                            <div className='actualTab' id='Forecast' onClick={changeTabSelected} style={{ backgroundColor: TabSelected === 'Forecast' ? '#213038' : '#ffffff', color: TabSelected === 'Forecast' ? '#fff' : '#213038' }}><p>Forecasts</p></div>
                            <div className='forecastTab' id='Actual' onClick={changeTabSelected} style={{ backgroundColor: TabSelected === 'Actual' ? '#213038' : '#ffffff', color: TabSelected === 'Actual' ? '#fff' : '#213038' }}><p>Actuals</p></div>
                        </div>
                        <img style={{ width: '3%', cursor: 'pointer' }} src={AddImg} alt='' onClick={addNewMetricClicked} />
                    </div>
                    <div className='metricSection'>
                        <div className='metricTopSect'>
                            <p style={{ marginLeft: '1%', width: '29.3%', fontSize: '20px', fontWeight: '300' }}>Type</p>
                            <p style={{ width: '30%', fontSize: '20px', fontWeight: '300' }}>Date</p>
                        </div>
                        <div className='mappedMetricsSect'>
                            {MetricsData.filter(item => item.Type === TabSelected).map((item, index) => {
                                return (
                                    <div className='availMetricCArd' key={index}>
                                        <div style={{ width: '95%', display: 'flex', flexDirection: 'row' }}>
                                            <p style={{ marginLeft: '1%', width: '30%' }}>{item.Type}</p>
                                            <p style={{ marginLeft: '1%', width: '30%' }}>{formatDateToMonthYear(item.Date)}</p>
                                        </div>
                                        <div style={{ width: '5%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
                                            <img style={{ width: '30%', cursor: 'pointer' }} src={EditSVG} alt='' onClick={() => handleUpdateMetric(item)} />
                                            <img style={{ width: '30%', cursor: 'pointer' }} src={DeleteSVG} alt='' onClick={() => handleDeleteMetric(item)} />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div> :
                <div className='overviewCharts'>
                    <div className='newHorizontalChartSection'>
                        <div className='filteredDateandTotalsSect' style={{ justifyContent: Filter === 'Entire' ? 'center' : 'space-between' }}>
                            {(Filter !== 'Entire') ?
                                <img src={LeftArrow} alt='' style={{ cursor: 'pointer' }} onClick={handleMoveBackward} />
                                : null}
                            <p>Revenue {formatDate(SelectedFilter.Date)} - <span style={{ color: '#D4912D' }}>
                                R{SelectedFilter.CurrentMonth.Income !== undefined ? SelectedFilter.CurrentMonth.Income.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                            </span></p>
                            {(Filter !== 'Entire') ?
                                <img src={RightArrow} alt='' style={{ cursor: 'pointer' }} onClick={handleMoveForward} />
                                : null}
                        </div>
                        <div className='horizontalChartSect' ref={graphSectionRef}>
                            <CashflowGraph Actual={SelectedFilter} currentAct={SelectedFilter.CurrentMonth.Income} currentFore={SelectedFilter.CurrentMonth.ForecastIncome} previousAct={SelectedFilter.PreviousMonth.Income} previousFore={SelectedFilter.PreviousMonth.ForecastIncome} width={dimensions.width} height={dimensions.height - 15} date={formatDate(SelectedFilter.Date)} prevDate={formatDate(SelectedFilter.PreviousDate)} type='Income' />
                        </div>
                        <div className='legendsSection'>
                            <div className='actual-Label' /><p>Actual</p>
                            <div className='forecast-Label' /><p>Forecast</p>
                            <div className='difference-Label' /><p>Difference</p>
                        </div>
                    </div>
                    <div className='newHorizontalChartSection'>
                        <div className='filteredDateandTotalsSect' style={{ justifyContent: Filter === 'Entire' ? 'center' : 'space-between' }}>
                            {(Filter !== 'Entire') ?
                                <img src={LeftArrow} alt='' style={{ cursor: 'pointer' }} onClick={handleMoveBackward} />
                                : null}
                            <p>Expenses {formatDate(SelectedFilter.Date)} - <span style={{ color: '#D4912D' }}>
                                R{SelectedFilter.CurrentMonth.Expenses !== undefined ? SelectedFilter.CurrentMonth.Expenses.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                            </span></p>
                            {(Filter !== 'Entire') ?
                                <img src={RightArrow} alt='' style={{ cursor: 'pointer' }} onClick={handleMoveForward} />
                                : null}
                        </div>
                        <div className='horizontalChartSect' ref={graphSectionRef}>
                            <CashflowGraph Actual={SelectedFilter} currentAct={SelectedFilter.CurrentMonth.Expenses} currentFore={SelectedFilter.CurrentMonth.ForecastExpenses} previousAct={SelectedFilter.PreviousMonth.Expenses} previousFore={SelectedFilter.PreviousMonth.ForecastExpenses} width={dimensions.width} height={dimensions.height - 15} date={formatDate(SelectedFilter.Date)} prevDate={formatDate(SelectedFilter.PreviousDate)} type='Expenses' />
                        </div>
                        <div className='legendsSection'>
                            <div className='actual-Label' /><p>Actual</p>
                            <div className='forecast-Label' /><p>Forecast</p>
                            <div className='difference-Label' /><p>Difference</p>
                        </div>
                    </div>
                    <div className='newOverViewcard'>
                        <div className='runwaySection'>
                            <div className='gaugeContainer'>
                                <RunwayGauge style={{ marginTop: '6%', width: '165px' }} width={200} height={200} value={runwayData} />
                                <p>Runway: {runwayData || 0} Months</p>
                            </div>
                            <div className='forecastToogleSwitch'>
                                <p>Forecast</p>
                                <label className="switch">
                                    <input type="checkbox" checked={isToggled} onChange={handleToggle} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className='chartContainer' ref={overViewSectionref}>
                            <BurnRateGraph width={overViewDimensions.width} height={overViewDimensions.height} data={chartData} isForecast={isToggled} exclude={LegendsFilter} />
                        </div>
                        <div className='availLegendsContainer'>
                            <div className='income-Label' style={{ backgroundColor: LegendsFilter.includes('Income') ? 'grey' : '', cursor: 'pointer' }} onClick={legendClicked} id='Income' />
                            <p style={{ marginRight: '5%', WebkitTextStroke: LegendsFilter.includes('Income') ? '0px #213038' : 'none', textDecoration: LegendsFilter.includes('Income') ? 'line-through' : 'none', cursor: 'pointer' }} id='Income' onClick={legendClicked}>Revenue</p>
                            <div className='expense-Label' style={{ backgroundColor: LegendsFilter.includes('Expenses') ? 'grey' : '', cursor: 'pointer' }} onClick={legendClicked} id='Expenses' />
                            <p style={{ marginRight: '5%', WebkitTextStroke: LegendsFilter.includes('Expenses') ? '0px #213038' : 'none', textDecoration: LegendsFilter.includes('Expenses') ? 'line-through' : 'none', cursor: 'pointer' }} id='Expenses' onClick={legendClicked}>Expenses</p>
                            <div className='burnrate-Label' style={{ backgroundColor: LegendsFilter.includes('Burnrate') ? 'grey' : '', cursor: 'pointer' }} onClick={legendClicked} id='Burnrate' />
                            <p style={{ marginRight: '5%', WebkitTextStroke: LegendsFilter.includes('Burnrate') ? '0px #213038' : 'none', textDecoration: LegendsFilter.includes('Burnrate') ? 'line-through' : 'none', cursor: 'pointer' }} id='Burnrate' onClick={legendClicked}>Burnrate</p>
                            <div className='cashflow-Label' style={{ backgroundColor: LegendsFilter.includes('Cashflow') ? 'grey' : '', cursor: 'pointer' }} onClick={legendClicked} id='Cashflow' />
                            <p style={{ marginRight: '5%', WebkitTextStroke: LegendsFilter.includes('Cashflow') ? '0px #213038' : 'none', textDecoration: LegendsFilter.includes('Cashflow') ? 'line-through' : 'none', cursor: 'pointer' }} id='Cashflow' onClick={legendClicked}>Cashflow</p>
                        </div>
                    </div>
                </div>}
            {(AddNewMetric) ?
                <div className='handlerAddMetrics'>
                    <img className='closeCardSect' src={CloseCard} onClick={closeAddMetric} />

                    <div className='dateAndTypeSect'>
                        <CustomSelect className='departmentInput' onMouseEnter={handleHoverStart} onMouseLeave={handleHoverEnd} Active={IsHovered} id='Type' placeholder='Type *' style={{ marginTop: 'unset' }} value={metrics.Type}>
                            <div value='Forecast' id='Forecast' onClick={typeChange} className={`${(metrics.Type === 'Forecast') ? 'selectActive' : 'customOptions'}`}>Forecast</div>
                            <div value='Actual' id='Actual' onClick={typeChange} className={`${(metrics.Type === 'Actual') ? 'selectActive' : 'customOptions'}`}>Actual</div>
                        </CustomSelect>
                        {(metrics.Type !== 'Actual') ?
                            <div className='changeStatusHover' onMouseEnter={addMonth} onMouseLeave={closeMonth}>
                                <p className='placeHolder'>Month/Date</p>
                                <p>{CredsDate || '00/00/0000'}</p>
                                {(AddMonth) ?
                                    <div className='addCredsMonthSelector'>
                                        <Calender onClick={getDate} />
                                    </div> : null}
                            </div> : null}
                    </div>
                    <hr className='hr' style={{ width: '93%' }} />
                    <div className='incomeConatiner'>
                        <p>Income</p>
                        <input type='number' value={metrics.Income.Income[0].Amount} onChange={(e) => handleChange(e, 'Income')} />
                    </div>
                    <div className='incomeConatiner'>
                        <p>Expenses</p>
                        <input type='number' placeholder='R0.00' value={metrics.Expenses.Operating_Expenses[0].Amount} onChange={(e) => handleChange(e, 'Expenses')} />
                    </div>
                    <div className='incomeConatiner'>
                        <p>Cashflow</p>
                        <input type='number' placeholder='R0.00' value={metrics.Cashflow.Cash_Flow[0].Amount} onChange={(e) => handleChange(e, 'Cashflow')} />
                    </div>
                    <div className='incomeConatiner'>
                        <p>Burnrate</p><input value={burnRate.toFixed(2)} />
                    </div>
                    <div className='btnSection'>
                        <button className='saveBtn' onClick={saveMeterics}>Save Metric</button>
                    </div>
                </div> : null}
            {(UpdateMetric) ?
                <div className='handlerAddMetrics'>
                    <img className='closeCardSect' src={CloseCard} onClick={closeAddMetric} />
                    <div className='dateAndTypeSect'>
                        <div className='monthTypeContainer'>
                            <p className='placeHolder'>Metric Type</p>
                            <p className='values'>{SelectedMetric.NewMetric.Type}</p>
                        </div>
                        <div className='monthTypeContainer'>
                            <p className='placeHolder'>Metric Date</p>
                            <p className='values'>{formatDateToMonthYear(SelectedMetric.NewMetric.Date)}</p>
                        </div>
                    </div>
                    <hr className='hr' style={{ width: '93%' }} />
                    <div className='incomeConatiner'>
                        <p>Income</p>
                        <input type='number' value={SelectedMetric.NewMetric.Income} id='Income' onChange={(e) => handleUpdateMetricValues(e, 'Income')} />
                    </div>
                    <div className='incomeConatiner'>
                        <p>Expenses</p>
                        <input type='number' value={SelectedMetric.NewMetric.Expenses} id='Expenses' onChange={(e) => handleUpdateMetricValues(e, 'Expenses')} />
                    </div>
                    <div className='incomeConatiner'>
                        <p>Cashflow</p>
                        <input type='number' value={SelectedMetric.NewMetric.Cashflow} id='Cashflow' onChange={(e) => handleUpdateMetricValues(e, 'Cashflow')} />
                    </div>
                    <div className='incomeConatiner'>
                        <p>Burnrate</p><input value={SelectedMetric.NewMetric.Burnrate} id='Burnrate' onChange={(e) => handleUpdateMetricValues(e, 'Burnrate')} />
                    </div>
                    <div className='btnSection'>
                        <button className='saveBtn' onClick={updateMetricClicked}>Update Metric</button>
                    </div>
                </div> : null}
        </div >
    );
});

export default Reports;