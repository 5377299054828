import React from 'react';
import { observable, action, makeObservable } from 'mobx';
import { Auth, Users, Companies, jobs, Courses, Creator, OkrSessions, Aions, SpreadSheet, Client, Xero, HubSpot } from '../services/requests';
import Papa from 'papaparse';
import axios from 'axios';
import { act } from 'react-dom/test-utils';

export default class Store {

  // #region observablesupdateCompaniesByName
  @observable isLoading = false;
  // #endregion
  @observable isLoggedIn = false;
  @observable user = {};
  @observable jobs = {};
  @observable screenWidth = window.innerWidth;
  @observable screenHeight = window.innerHeight;
  // #endregion

  // #region Constructor
  constructor() {
    makeObservable(this);
  }
  // #endregion

  // #region Auth

  @action setJWT(token) {
    window.localStorage.setItem('jwt', token);
  }
  @action async login(email, data) {
    let retObj = await Auth.login(email, data);
    if (retObj.data.success === false) {
      return false;
    }
    this.setJWT(retObj.data.jwt)
    this.isLoggedIn = true;
    this.user = retObj.data.users;
    return true
  }
  @action logout() {
    window.localStorage.clear();
    this.isLoggedIn = false;
    this.user = {};
  }

  // #endregion

  // #region Company
  @action async createCompanies(data) {
    let retObj = await Companies.createCompanies(data)
    return true
  }
  @action async getCompaniesSheetData(data) {
    let retObj = await Companies.getCompaniesSheetData(data);
    if (retObj.data.company === true) {
      return true
    } else {
      return false
    }
  }
  @action async getCompanyByID(id) {
    let retObj = await Companies.getACompanyByID(id);
    return retObj.data.selectedCompany.companies
  }
  @action async getCompanies() {
    let retObj = await Companies.getCompanies();

    return retObj.data.companies
  }
  @action async getCompaniesByPerms(email) {
    let retObj = await Companies.getCompaniesByPerms(email);
    return retObj.data.companies
  }
  @action async updateCompaniesByName(name) {
    let retObj = await Companies.updateCompaniesByName(name)
    return retObj.data
  }
  @action async uploadDocs(data, name) {
    let retObj = await Companies.updateCompDocsByName(name, data)
    return retObj.data
  }
  @action async updateCompanyById(id, data) {
    let retObj = await Companies.updateCompaniesById(id, data)
    return retObj.data.companies
  }
  @action async updateCompByID(id, data) {
    let retObj = await Companies.updateCompByID(id, data);
    return retObj.data.updateComp
  }
  @action async csvToArray(data, company, csvInputID) {
    if (!data) {
      return
    };
    let ActualDetails = {
      TotalSalesDetails: [],
      NumberOfMeetingsDetails: [],
      NewCustomersDetails: [],
      AverageRevenuePerCustomerDetails: [],
      OperatingExpensesDetails: [],
      RecurringRevenueDetails: [],
      TotalClientsDetails: [],
      CostOfSalesDetails: []
    };
    let actualObj = {
      TotalSales: 0,
      NumberOfMeetings: 0,
      NewCustomers: 0,
      AverageRevenuePerCustomer: 0,
      OperatingExpenses: 0,
      RecurringRevenue: 0,
      TotalClients: 0,
      CostOfSales: 0,
      Date: new Date(),
      ActualDetails: ActualDetails
    }
    let objActDetails = {
      Category: '',
      Amount: 0,
    }
    const reader = new FileReader();
    const fileContent = await new Promise((resolve, reject) => {
      reader.onload = function (event) {
        resolve(event.target.result);
      };

      reader.onerror = function (error) {
        reject(error);
      };
      reader.readAsText(data);
    });

    let file = Papa.parse(fileContent);
    let dataArray = [];

    for (let i = 0; i < file.data.length; i++) {
      let currentIndex = file.data[i];
      let tempObj = {
        Date: currentIndex[0],
        type: currentIndex[1],
        category: currentIndex[2],
        amount: currentIndex[3]
      }
      dataArray.push(tempObj);
    };
    while (dataArray.length > 1) {
      let firstDate = new Date(dataArray[1].Date);
      const startDate = new Date(firstDate);
      startDate.setDate(startDate.getDate() - ((startDate.getDay() + 6) % 7) + 1); // Set to Monday

      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 6);

      const tempData = dataArray.filter(obj => {
        const date = new Date(obj.Date);
        return date >= startDate && date <= endDate;
      });

      //TODO: Process data in temp array for that week
      dataArray = dataArray.filter(obj => {
        const date = new Date(obj.Date);
        return date < startDate || date > endDate;
      });
      if (dataArray.length > 0) {
        ActualDetails = {
          TotalSalesDetails: [],
          NumberOfMeetingsDetails: [],
          NewCustomersDetails: [],
          AverageRevenuePerCustomerDetails: [],
          OperatingExpensesDetails: [],
          RecurringRevenueDetails: [],
          TotalClientsDetails: [],
          CostOfSalesDetails: []
        };
        for (let j = 0; j < dataArray.length; j++) {
          if (dataArray[j].type === 'TotalSalesDetails') {
            objActDetails = {
              Category: dataArray[j].category,
              Amount: dataArray[j].amount,
              date: dataArray[j].Date
            };
            ActualDetails.TotalSalesDetails.push(objActDetails);
          }
        }
      }
    }
    return
    if (csvInputID === 'actualCsv') {
      try {
        const retObj = await Companies.uploadActualCsvByID(company._id, {});
        return retObj;
      } catch (error) {
        console.error(error);
      }
    } else if (csvInputID === 'targetCsv') {
      try {
        const retObj = await Companies.uploadTargetCsvByID(company._id, {});
        return retObj;
      } catch (error) {
        console.error(error);
      }
    } else {
      window.alert('Invalid csvInputID provided.');
    }
  }

  // #endregion

  // #region job
  @action async createJob(data) {
    try {
      let response = await jobs.createJob(data);
      return true
    } catch (e) {
      return false
    }
  }
  @action async createMeeting(data) {
    try {
      let response = await jobs.createMeeting(data);
      return true
    } catch (e) {
      return false
    }
  }
  @action async getJob() {
    let retObj = await jobs.getJob();
    return retObj.data.job
  }
  @action async getJobByEmail(email) {
    let retObj = await jobs.getJobByEmail(email);

    return retObj.data.job
  }
  // #endregion

  //#region  Task complete
  // Accept or Reject Credits
  @action async clientAcceptCreds(data) {
    var response;
    let task = data;
    try {
      response = await jobs.clientAcceptCredits(task._id, task);
      if (!response.data.success) {
        return 'error'
      }
      return response.data.job
    } catch (e) {
      return 'error'
    }
  }
  @action async clientDeclineCreds(data) {
    var response;
    let task = data;
    try {
      response = await jobs.clientDeclineCredits(task._id, task);
      if (!response.data.success) {
        return 'error'
      }
      return response.data.job
    } catch (e) {
      return 'error'
    }
  }
  @action async departmentAccept(data) {
    var response;
    let task = data;
    try {
      response = await jobs.departmentAccept(task._id, task);
      if (!response.data.success) {
        return 'error'
      }
      return response.data.job
    } catch (e) {
      return 'error'
    }
  }
  @action async departmentReject(data) {
    var response;
    let task = data;
    try {
      response = await jobs.departmentDecline(task._id, task);
      if (!response.data.success) {
        return 'error'
      }
      return response.data.job
    } catch (e) {
      return 'error'
    }
  }
  @action async departmentTaskComplete(data) {
    var response;
    let task = data;
    try {
      response = await jobs.departmentCompleteTask(task._id, task);
      if (!response.data.success) {
        return 'error'
      }
      return response.data.job
    } catch (e) {
      return 'error'
    }
  }
  @action async updateTaskById(data) {
    var response;
    let task = data;
    try {
      response = await jobs.updateTaskById(task._id, task);
      if (!response.data.success) {
        return 'error'
      }
      return response.data.job
    } catch (e) {
      return 'error'
    }
  }
  //#endregion

  // #region OkrSessions
  @action async updateOkrSessionsById(id, data) {
    var response;
    try {
      response = await jobs.updateJobById(id, data);
      if (!response.data.success) {
        return 'error'
      }
      return response.data.okrSessions
    } catch (e) {
      return 'error'
    }
  }
  @action async getOKrSessionByID(id) {
    let retObj = await OkrSessions.getOKrSessionByID(id);
    return retObj.data.OKrSessions
  }
  @action async getOkrSessions() {
    let retObj = await OkrSessions.getOkrSessions();
    return retObj.data.OkrSessions
  }
  @action async createOkrSession(data) {
    await OkrSessions.createOkrSession(data)
    return true
  }
  // #endregion

  //#region courses
  @action async createCourse(data) {
    await Courses.createCourse(data)
    return true
  }
  @action async getCourse() {
    let retObj = await Courses.getCourse();
    return retObj.data.course
  }
  @action async getCoursesByID(id) {
    let retObj = await Courses.getCourseByID(id);
    return retObj.data.course
  }
  @action async updateCourseById(id, data) {
    var response;
    try {
      response = await Courses.updateCourseByID(id, data);
      if (!response.data.success) {
        return 'error'
      }
      return response.data.Course
    } catch (e) {
      return 'error'
    }
  }

  //#endregion

  //#region Password Regex
  @action async isPasswordValid(password) {
    // Regular expressions to match each required character type
    const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    const hasLowerCase = /[a-z]/;
    const hasUpperCase = /[A-Z]/;
    const hasNumber = /[0-9]/;

    // Check if password meets all criteria
    const hasSpecial = hasSpecialChar.test(password);
    const hasLower = hasLowerCase.test(password);
    const hasUpper = hasUpperCase.test(password);
    const hasNumberChar = hasNumber.test(password);

    return hasSpecial && hasLower && hasUpper && hasNumberChar;
  }
  //#endregion

  // #region User
  @action async createUsers(data) {
    let retObj = await Users.createUsers(data)
    return retObj
  }
  @action async getUsers() {
    let retObj = await Users.getUsers();

    return retObj.data.users
  }
  @action async getUserByEmail(email) {
    let retObj = await Users.getUserByEmail(email);
    return retObj.data.users
  }
  @action async patchUsers(email, data) {
    let retObj = await Users.updateUserByEmail(email, data);
    return retObj.data.users
  }
  @action async newUserPassword(data, token) {
    let retobj = await Users.createUserPassword(data, token);
    this.setJWT(retobj.data.jwt);
    return retobj
  }
  @action async patchUsersPassword(email, data) {
    let retObj = await Users.changePassword(email, data);
    return retObj.data.users
  }
  @action async extractParams() {
    const getParameterByName = (name, url) => {
      if (!url) url = window.location.href;
      name = name.replace(/[[]]/g, "\\$&");
      const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
      const results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    };
    const email = getParameterByName('e');
    const jwtToken = getParameterByName('j');

    if (email === '' | jwtToken === '') {
      return 'error'
    } else {
      const data = {
        Email: email,
        Token: jwtToken
      };
      return data
    };
  }
  // #endregion

  // #region helpers
  @action setLoading(val) {
    this.isLoading = val;
  }

  // #endregion

  //#region Aions
  @action async createAions(data) {
    let retobj = await Aions.createAions(data);
    return retobj.data.aions
  }

  @action async createAionsData(data) {
    await Aions.createAionsData(data);
    return true
  }
  @action async getAions() {
    let retObj = await Aions.getAions();
    return retObj.data.aions
  }
  @action async getAionsByID(id) {
    let retObj = await Aions.getAionsByID(id);
    return retObj.data.aions
  }

  @action async removeAions(id) {
    let retObj = await Aions.deleteAionsByID(id);

    return retObj.data.aions
  }

  @action async aionsDeleteAll() {
    let retObj = await Aions.aionsDeleteAll();
    return retObj.data.aions
  }
  @action async updateAionsByID(id, data) {
    var response;
    try {
      response = await Aions.patchAionsByID(id, data);
      if (!response.data.success) {
        return 'error'
      }
      return response.data.aions
    } catch (e) {
      return 'error'
    }
  }

  //#endregion

  // #region Creator
  @action async createUserCreator(data) {
    let retObj = await Creator.createCreator(data)

    return retObj
  }
  @action async getUserCreator() {
    let retObj = await Creator.getUserCreator();

    return retObj.data
  }
  @action async patchUserCreator(email, data) {
    let retObj = await Creator.updatCreator(email, data);

    return retObj.data.creators
  }
  // #endregion

  //#region AEX Data

  @action async getSheetData() {
    let retObj = await SpreadSheet.getSheetData();
    return retObj.data.data
  }
  @action async getAionsAV() {
    let retObj = await SpreadSheet.getAionsAV();
    return retObj.data.data
  }
  @action async getDeals() {
    let retObj = await HubSpot.getDeals();
    return retObj.data.deals
  }

  @action async getXero() {
    let retObj = await Xero.getXeroOath();
    return retObj.data.xero
  }
  //#endregion

  // #region Admin Home Page
  @action async calculateAdminCompanyOverview(companies) {
    let companyOverview = [];
    let overview = {
      Name: '',
      _id: '',
      totalSales: 0,
      totalSalesTarget: 0,
      totalSalesDifference: 0,
      totalRevenue: 0,
      totalRevenueTarget: 0,
      totalRevenueDifference: 0,
    };
    for (let i = 0; i < companies.length; i++) {
      let company = companies[i];
      if (!company.Target || company.Target.length === 0) {
        overview = {
          Name: company.Name,
          _id: company._id,
          totalSales: 0,
          totalSalesTarget: 0,
          totalSalesDifference: 0,
          totalRevenue: 0,
          totalRevenueTarget: 0,
          totalRevenueDifference: 0,
          colour: ''
        };
        companyOverview.push(overview);
        continue;
      }
      // Calculate weeks
      let startDate = new Date(company.Date);
      startDate.setHours(0, 0, 0, 0);
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
      const timeDifference = currentDate.getTime() - startDate.getTime();
      const weeksPassed = Math.floor(timeDifference / oneWeekInMilliseconds);
      // Calculate target so far
      let salesTarget = (company.Target[company.Target.length - 1].TotalSales || 0) / 52 * weeksPassed;
      let recurringRevenueTarget = (company.Target[company.Target.length - 1].RecurringRevenue || 0) / 52 * weeksPassed;
      // Calculate Actuals so far
      let actualSales = 0;
      let actualRevenue = 0;
      for (let j = 0; j < company.Actual.length; j++) {
        actualSales += company.Actual[j].TotalSales || 0;
        actualRevenue += company.Actual[j].RecurringRevenue || 0;
      }

      overview = {
        Name: company.Name,
        _id: company._id,
        totalSales: actualSales,
        totalSalesTarget: salesTarget,
        totalSalesDifference: actualSales - salesTarget,
        totalRevenue: actualRevenue,
        totalRevenueTarget: recurringRevenueTarget,
        totalRevenueDifference: actualRevenue - recurringRevenueTarget,
      };

      if (overview.totalSalesDifference >= 0 && overview.totalRevenueDifference >= 0) {
        overview.colour = 'green';
      } else if (overview.totalSalesDifference < 0 && overview.totalRevenueDifference < 0) {
        overview.colour = 'red';
      } else {
        overview.colour = 'orange';
      }
      companyOverview.push(overview);
    }
    return companyOverview;
  }
  // #endregion

  // #region Client
  @action async createClient(data) {
    let retObj = await Client.createClient(data)
    return retObj
  }
  @action async getClients() {
    let retObj = await Client.getClient();
    return retObj.data.client
  }
  @action async updateClientById(id, data) {
    let retObj = await Client.patchClientByID(id, data);
    return retObj.data.client
  }
  @action async getClientByID(id) {
    let retObj = await Client.getClientByID(id);
    return retObj.data.Client

  }
  // #endregion

  @action setScreenWidth(width) {
    this.screenWidth = width;
  }
  @action setScreenHeight(height) {
    this.screenHeight = height;
  }

  @action getScreenWidth() {
    return this.screenWidth;
  }
  @action getScreenHeight() {
    return this.screenHeight;
  }

}

// #region storeConfig

const StoreContext = React.createContext();

export const StoreProvider = ({ children, store }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export const useStore = () => React.useContext(StoreContext);

export const withStore = (Component) => (props) => {
  return <Component {...props} store={useStore()} />;
};

// #endregion