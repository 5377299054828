import React, { useEffect, useState, updateState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { Textbox, CustomSelect } from '../../../components';
import Filter from '../../../assets/images/Filter.svg';
import FilterDown from '../../../assets/images/FilterDown.svg';

const Reports = observer(({ pageSelected }) => {
    const store = useStore();
    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [FilterComps, setFilterComps] = useState('A-Z');
    const [Companies, setCompanies] = useState([]);
    useEffect(() => {
        getCompanies();
    }, []);
    const getCompanies = async () => {
        store.setLoading(true);
        let comps = await store.getCompanies();
        let companies = [];
        if (comps === "error" || typeof comps === "string" || !comps) {
            setCompanies([]);
            window.alert('Try Harder!')
            store.setLoading(false);
            return;
        } else {
            for (let i = 0; i < comps.length; i++) {
                let comp = comps[i];
                if (!comp.Actual || !Array.isArray(comp.Actual) || comp.Actual.length === 0 || comp.Actual.some(item => item.hasOwnProperty('ActualDetails') || item.hasOwnProperty('ActualsDetails'))) {
                    comp.Profit = 0;
                } else {
                    let data = comp.Actual;
                    let CurrentMonth = {
                        Income: 0,
                        Expenses: 0,
                    }
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].Type === 'Actual') {
                            let income = data[i].Income;
                            let expenses = data[i].Expenses;
                            const totalIncome = Object.values(income).reduce((acc, arr) => {
                                return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                            }, 0);
                            const totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                                return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                            }, 0);
                            CurrentMonth.Income += totalIncome;
                            CurrentMonth.Expenses += totalExpenses;
                        }
                    }
                    comp.Profit = CurrentMonth.Income - CurrentMonth.Expenses
                }
                companies.push(comp);
            }
        }
        setCompanies(companies.sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0)));
        store.setLoading(false);
        forceUpdate();
    }
    const viewCompReport = (index) => {
        let comp = Companies[index];
        pageSelected(comp._id);
        forceUpdate();
    }
    const handleFilterComps = () => {
        let comps = Companies;
        if (FilterComps === 'Z-A') {
            setFilterComps('A-Z');
            comps = comps.sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0));
        } else {
            setFilterComps('Z-A');
            comps = comps.sort((a, b) => (a.Name > b.Name ? -1 : a.Name < b.Name ? 1 : 0));
        }
        setCompanies(comps);
        forceUpdate();
    };
    return (
        <div className='companiesReportPage'>
            <div className='compNameHeader'>
                <h2 style={{ fontSize: '25px', fontWeight: 700, marginLeft: '1%', display: 'flex', color: '#213038', cursor: 'pointer', width: '30%' }} onClick={handleFilterComps}>
                    {(FilterComps === '') ? null : (
                        <img src={FilterComps === 'A-Z' ? FilterDown : Filter} style={{ marginRight: '0.5%' }} />
                    )}
                    Company Name</h2>
            </div>
            <div className='availComps'>
                {Companies.map((item, index) => {
                    if (item.Name === 'AGH') {
                        return
                    }
                    return (
                        <div className='compContainer' key={index} onClick={() => viewCompReport(index)}>
                            <p style={{ marginLeft: '2%' }}>{item.Name}</p>
                            <div className='profitSection' style={{ cursor: 'pointer' }}>
                                <p style={{ marginLeft: '3%', cursor: 'pointer' }}>View Report</p>
                                {/* <div className='profitLossValue'>
                                    <p>R{item.Profit !== undefined ? (item.Profit < 0 ? '-' : '') + Math.abs(item.Profit).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</p>
                                </div> */}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
});

export default Reports;