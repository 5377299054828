import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { useNavigate } from 'react-router-dom';
import './style.scss'
import { Input, View, Button, Card, ReportExport, CashflowGraph, BurnRateGraph, RunwayGauge } from '../../../components';
import LeftArrow from '../../../assets/images/leftArrow.svg';
import RightArrow from '../../../assets/images/rightArrow.svg';
import VerticalGraph from '../../../components/VerticalGraph/VerticalGraph';
import { timeMonday } from 'd3';
import { PDFDownloadLink } from "@react-pdf/renderer";
import CloseArchive from '../../../assets/images/closeArchive.svg';
var mobile = require('is-mobile');

const Report = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const graphSectionRef = useRef();
    const overViewSectionref = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [overViewDimensions, setOverviewDimensions] = useState({ width: 0, height: 0 })
    const [OnHover, setOnHover] = useState(false);
    const [Filter, setFilter] = useState('Month');
    const [Onset, setOnset] = useState(0);
    const [Company, setCompany] = useState({});
    const [Actuals, setActuals] = useState([]);
    const [ReportGenerated, setReportGenerated] = useState(false);
    const [InitialDate, setInitialDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1));
    const [SelectedFilter, setSelectedFilter] = useState({
        CurrentMonth: {},
        PreviousMonth: {}
    });
    const [IsLabelOn, setIsLabelOn] = useState(false);
    const [isToggled, setIsToggled] = useState(false);
    const [LegendsFilter, setLegendsFilter] = useState([]);

    // Runway state
    const [runwayData, setRunway] = useState(0);

    // Step 2: Function to update the runway data state
    const updateRunway = (newData) => {
        setRunway(newData);
    };

    // Step 1: Initialize the chart data state
    const [chartData, setData] = useState([]);

    // Step 2: Function to update the chart data state
    const updateData = (newData) => {
        setData(newData);
    };

    // Step 1: Initialize the vertical chart data state
    const [verticalChartData, setVerticalChartData] = useState([]);

    // Step 2: Function to update the chart data state
    const updateVerticalChartData = (newData) => {
        setVerticalChartData(newData);
    };

    // let filteredData;verticalChartData

    const excludedKeys = ['Type', 'Income', 'Costs', 'Expenses', 'Cashflow', 'Burnrate', 'ForecastBurnrate', 'ForecastCashflow', 'ForecastExpenses', 'ForecastIncome'];
    useEffect(() => {
        getCompany();
        const updateDimensions = () => {
            if (graphSectionRef.current) {
                setDimensions({
                    width: graphSectionRef.current.offsetWidth,
                    height: graphSectionRef.current.offsetHeight,
                });
            }
            if (overViewSectionref.current) {
                setOverviewDimensions({
                    width: overViewSectionref.current.offsetWidth,
                    height: overViewSectionref.current.offsetHeight,
                });
            }
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);
    const getCompany = async () => {
        store.setLoading(true);
        let comps = await store.getCompaniesByPerms();
        if (typeof comps === "string" || !comps) {
            setCompany({});
            store.setLoading(false);
        }
        if (comps.length === 0) {
            setCompany({});
            store.setLoading(false);
        }
        let comp = comps.filter(item => item.Name === store.user.Company);
        setCompany(comp[0]);
        if (comp.length === 0) {
            setCompany({});
            store.setLoading(false);
        }
        store.setLoading(false);
        let updatedComp = await store.getCompaniesByPerms();
        if (updatedComp.length === 0) {
            setCompany({});
            store.setLoading(false);
        }
        let updComps = updatedComp.filter(item => item.Name === store.user.Company);
        let acts = updComps[0].Values;
        let onset = 0;
        handleMonthlyData(acts, onset);
        let finalData = [];
        let actuals = [];
        let forecasts = [];
        for (let i = 0; i < acts.length; i++) {
            let tempActs = acts[i];
            if (tempActs.Type === 'Forecast') {
                forecasts.push(tempActs);
            } else if (tempActs.Type === 'Actual') {
                actuals.push(tempActs);
            }
        }
        // Line chart calculations.
        const currentDate = new Date();
        const currMonthObj = actuals.map(item => ({ ...item, Date: new Date(item.Date) })).sort((a, b) => b.Date - a.Date)[0];
        let latestDate = new Date(currMonthObj.Date);
        let currCashflow = 0;
        if (currMonthObj && currMonthObj.Cashflow) {
            currCashflow = Object.values(currMonthObj.Cashflow).reduce((acc, arr) => {
                return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
            }, 0);
        };
        let currBurnrate = 0;
        if (currMonthObj && currMonthObj.Burnrate) {
            currBurnrate = Object.values(currMonthObj.Burnrate).reduce((acc, arr) => {
                return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
            }, 0);
        };
        let runway = calculateRunway(currCashflow, currBurnrate);
        if (runway > 0) {
            runway = Number(runway)
        } else {
            runway = 0
        }
        setRunway(runway);
        const endOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        const startOfFollowingMonth = new Date(latestDate.getFullYear(), latestDate.getMonth() + 1, 1);
        const filteredForecasts = forecasts.filter(forecast => {
            const forecastDate = new Date(forecast.Date);
            return forecastDate >= startOfFollowingMonth;
        });
        for (let i = 0; i < actuals.length; i++) {
            let item = actuals[i];
            let income = item.Income;
            let expenses = item.Expenses;
            let cashflow = item.Cashflow;
            let burnrate = item.Burnrate;
            let type = item.Type;
            let date = item.Date;
            let totalIncome = 0;
            if (income) {
                totalIncome = Object.values(income).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalExpenses = 0;
            if (expenses) {
                totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalCashflow = 0;
            if (cashflow) {
                totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalBurnrate = 0;
            if (burnrate) {
                totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let finalObj = {
                Income: totalIncome,
                Expenses: totalExpenses,
                Cashflow: totalCashflow,
                Burnrate: totalBurnrate,
                Type: type,
                date: new Date(date)
            }
            finalData.push(finalObj);
        }
        forecasts.forEach((item) => {
            let income = item.Income;
            let expenses = item.Expenses;
            let cashflow = item.Cashflow;
            let burnrate = item.Burnrate;
            let type = item.Type;
            let date = item.Date;
            let totalIncome = 0;
            if (income) {
                totalIncome = Object.values(income).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalExpenses = 0;
            if (expenses) {
                totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalCashflow = 0;
            if (cashflow) {
                totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalBurnrate = 0;
            if (burnrate) {
                totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let finalObj = {
                Income: totalIncome,
                Expenses: totalExpenses,
                Cashflow: totalCashflow,
                Burnrate: totalBurnrate,
                Type: type,
                date: new Date(date)
            }
            finalData.push(finalObj);
        });
        // Vertical bar chart calculations.
        let vertdata = [];

        const parseDate = (dateString) => new Date(dateString);
        const actualsArr = actuals.filter(item => parseDate(item.Date) <= latestDate).sort((a, b) => parseDate(b.Date) - parseDate(a.Date)).slice(0, 4);
        const nextMonth = new Date(latestDate);
        nextMonth.setMonth(latestDate.getMonth() + 1);
        const forecastArr = forecasts.filter(item => parseDate(item.Date) >= nextMonth).sort((a, b) => parseDate(a.Date) - parseDate(b.Date)).slice(0, 4);
        for (let i = 0; i < actualsArr.length; i++) {
            let item = actualsArr[i];
            let income = item.Income;
            let expenses = item.Expenses;
            let cashflow = item.Cashflow;
            let burnrate = item.Burnrate;

            let type = item.Type;
            let date = item.Date;
            let totalIncome = 0;
            if (income) {
                totalIncome = Object.values(income).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalExpenses = 0;
            if (expenses) {
                totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalCashflow = 0;
            if (cashflow) {
                totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalBurnrate = 0;
            if (burnrate) {
                totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let finalObj = {
                Income: totalIncome,
                Expenses: totalExpenses,
                Cashflow: totalCashflow,
                Burnrate: totalBurnrate,
                Type: type,
                date: new Date(date)
            }
            vertdata.push(finalObj);
        }
        forecastArr.forEach((item) => {
            let income = item.Income;
            let expenses = item.Expenses;
            let cashflow = item.Cashflow;
            let burnrate = item.Burnrate;
            let type = item.Type;
            let date = item.Date;
            let totalIncome = 0;
            if (income) {
                totalIncome = Object.values(income).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalExpenses = 0;
            if (expenses) {
                totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalCashflow = 0;
            if (cashflow) {
                totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let totalBurnrate = 0;
            if (burnrate) {
                totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                    return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                }, 0);
            };
            let finalObj = {
                Income: totalIncome,
                Expenses: totalExpenses,
                Cashflow: totalCashflow,
                Burnrate: totalBurnrate,
                Type: type,
                date: new Date(date)
            }
            vertdata.push(finalObj);
        });
        setVerticalChartData(vertdata);
        setData(finalData)
        setActuals(acts);
        forceUpdate();
        // let data = {
        //     SheetID: comp[0].SheetID,
        //     CompanyID: comp[0]._id,
        //     TabName: comp[0].TabName
        // }
        // if (data.SheetID === '' || data.SheetID === undefined || !data.SheetID) {
        //     window.alert('No Spreadsheet data available!');
        //     store.setLoading(false);
        //     return;
        // } else {
        //     let syncSheetData = await store.getCompaniesSheetData(data);
        //     if (syncSheetData === false) {
        //         window.alert(`Could\'nt pull ${comp[0].Name} data from the spreadsheet.`)
        //         store.setLoading(false);
        //     } else if (syncSheetData === true) {

        //     }
        // }
        store.setLoading(false);
    }
    // Get current month from actuals
    function getObjectMatchingCurrentMonth(data) {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth(); // 0-based (0 for January, 11 for December)
        const currentYear = currentDate.getFullYear();

        return data.find(item => {
            const itemDate = new Date(item.date);
            const itemMonth = itemDate.getMonth();
            const itemYear = itemDate.getFullYear();

            return itemMonth === currentMonth && itemYear === currentYear;
        });
    }

    // Calculate Runway based on a single month
    const calculateRunway = (cashflow, burnrate) => {
        const runway = cashflow / burnrate;
        return runway.toFixed(1);
    }

    const filterActualsUpToCurrentMonth = (data) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        // Function to check if amounts are valid
        const isValidAmount = (amount) => typeof amount === 'number' && !isNaN(amount);

        // Filter 'Actual' items up to the current date
        const actualData = data.filter(item => {
            const itemMonth = item.date.getMonth();
            const itemYear = item.date.getFullYear();

            return itemYear <= currentYear &&
                (itemYear < currentYear || itemMonth <= currentMonth) &&
                item.Type === 'Actual' &&
                isValidAmount(item.Income) &&
                isValidAmount(item.Expenses) &&
                isValidAmount(item.Burnrate) &&
                isValidAmount(item.Cashflow);
        });

        // Combine the results
        return [...actualData];
    }

    const filterDataUpToCurrentMonth = (data) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        // Function to check if amounts are valid
        const isValidAmount = (amount) => typeof amount === 'number' && !isNaN(amount);

        // Filter 'Actual' items up to the current date
        const actualData = data.filter(item => {
            const itemMonth = item.date.getMonth();
            const itemYear = item.date.getFullYear();

            return itemYear <= currentYear &&
                (itemYear < currentYear || itemMonth <= currentMonth) &&
                item.Type === 'Actual' &&
                isValidAmount(item.Income) &&
                isValidAmount(item.Expenses) &&
                isValidAmount(item.Burnrate) &&
                isValidAmount(item.Cashflow);
        });

        // Filter 'Forecast' items after the current date
        const forecastData = data.filter(item => {
            const itemMonth = item.date.getMonth();
            const itemYear = item.date.getFullYear();

            return itemYear > currentYear ||
                (itemYear === currentYear && itemMonth > currentMonth) &&
                item.Type === 'Forecast';
        }).slice(0, 4);  // Take only the next 4 'Forecast' items

        // Combine the results
        return [...actualData, ...forecastData];
    }
    const convertToChartData = (data) => {
        // Extract date from the string and convert to JavaScript Date object
        const date = new Date(data.Date);

        // Helper function to sum amounts in a given category array
        function sumAmounts(categoryArray) {
            return categoryArray.reduce((sum, item) => sum + item.Amount, 0);
        }

        // Sum Income amounts
        const eCommerceIncome = sumAmounts(data.Income.ECommerce_Income);
        const otherIncome = sumAmounts(data.Income.Income);
        const totalIncome = eCommerceIncome + otherIncome;

        // Sum Costs amounts (assuming total is required, as there's only one entry each)
        const costOfSales = sumAmounts(data.Costs.Cost_of_Sales);
        const costOfGoods = sumAmounts(data.Costs.Cost_of_Goods);
        const totalCosts = costOfSales + costOfGoods;

        // Sum Cashflow amounts
        const totalCashflow = sumAmounts(data.Cashflow.Cash_Flow);

        // Sum Burnrate amounts
        const totalBurnrate = sumAmounts(data.Burnrate.Burn_Rate);

        // Sum Expenses amounts
        const operatingExpenses = sumAmounts(data.Expenses.Operating_Expenses);
        const otherExpenses = sumAmounts(data.Expenses.Other_Expenses);
        const totalExpenses = operatingExpenses + otherExpenses;

        // Construct the simplified object
        const dataObject = {
            date: date,
            Income: totalIncome,
            Expenses: totalExpenses,
            Burnrate: totalBurnrate,
            Cashflow: totalCashflow,
            Type: data.Type
        };

        // Return the resulting array with one object
        return dataObject;
    }
    const handleHoverStart = () => {
        setOnHover(true);
        forceUpdate();
    }
    const handleHoverEnd = () => {
        setOnHover(false);
        forceUpdate();
    }
    const changeFilterBy = (e) => {
        setInitialDate(new Date());
        let filter = e.currentTarget.id;
        let acts = Actuals;
        let onset = 0;
        setOnset(onset)
        if (filter === 'Entire') {
            setFilter(filter);
            handleEntireData(acts);
        } else if (filter === 'Month') {
            setFilter(filter);
            handleMonthlyData(acts, onset);
        } else if (filter === 'Yearly') {
            setFilter(filter);
            handleYearlyData(acts, onset);
        }
        forceUpdate();
    }
    const handleMonthlyData = (acts, onset = Onset) => {
        store.setLoading(true);
        if (acts) {
            let DataObj = {};
            let currentDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
            let currentYear = currentDate.getFullYear();
            let currentMonth = currentDate.getMonth() + onset;
            let previousMonth = currentMonth - 1;

            let currentMonthData = acts.filter((item) => {
                const objDate = new Date(item.Date).getMonth();
                const objYear = new Date(item.Date).getFullYear();
                return objDate === currentMonth && objYear === currentYear;
            });

            let prevMonthData = acts.filter((item) => {
                const objDate = new Date(item.Date).getMonth();
                const objYear = new Date(item.Date).getFullYear();
                return objDate === previousMonth && objYear === currentYear;
            });

            let CurrentMonth = {
                Type: 'Monthly',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }
            let PreviousMonth = {
                Type: 'Monthly',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }
            for (let i = 0; i < currentMonthData.length; i++) {
                let data = currentMonthData[i];
                if (data.Type === 'Forecast') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    CurrentMonth.ForecastIncome += totalIncome;
                    CurrentMonth.ForecastBurnrate += totalBurnrate;
                    CurrentMonth.ForecastCashflow += totalCashflow;
                    CurrentMonth.ForecastExpenses += totalExpenses;
                } else if (data.Type === 'Actual') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    const calculateTotal = (data, key) => {
                        return Object.values(data[key]).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    const filteredData = Object.keys(data)
                        .filter(key => !['Type', 'Date', 'Income', 'Costs', 'Expenses', 'Cashflow'].includes(key))
                        .reduce((obj, key) => {
                            obj[key] = calculateTotal(data, key);
                            return obj;
                        }, {});
                    Object.assign(CurrentMonth, filteredData);
                    CurrentMonth.Income += totalIncome;
                    CurrentMonth.Burnrate += totalBurnrate;
                    CurrentMonth.Cashflow += totalCashflow;
                    CurrentMonth.Expenses += totalExpenses;
                }
            }
            for (let i = 0; i < prevMonthData.length; i++) {
                let data = prevMonthData[i];
                if (data.Type === 'Forecast') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    PreviousMonth.ForecastIncome += totalIncome;
                    PreviousMonth.ForecastBurnrate += totalBurnrate;
                    PreviousMonth.ForecastCashflow += totalCashflow;
                    PreviousMonth.ForecastExpenses += totalExpenses;
                } else if (data.Type === 'Actual') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    PreviousMonth.Income += totalIncome;
                    PreviousMonth.Burnrate += totalBurnrate;
                    PreviousMonth.Cashflow += totalCashflow;
                    PreviousMonth.Expenses += totalExpenses;
                }
            }
            DataObj.CurrentMonth = CurrentMonth;
            DataObj.PreviousMonth = PreviousMonth;
            DataObj.Date = new Date(currentYear, currentMonth);
            DataObj.PreviousDate = new Date(currentYear, currentMonth - 1)
            setSelectedFilter(DataObj);
            store.setLoading(false);
        } else {
            window.alert('No Actual available.');
            store.setLoading(false);
        }
        forceUpdate();
    };
    const handleYearlyData = (acts, onset = Onset) => {
        store.setLoading(true);
        if (acts) {
            let DataObj = {};
            let currentDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
            let currentYear = currentDate.getFullYear() + onset;
            let currentMonthDate = currentDate.getMonth();
            let currentMonthData = acts.filter((item) => {
                const objYear = new Date(item.Date).getFullYear();
                return objYear === currentYear;
            });
            let prevMonthData = acts.filter((item) => {
                const objYear = new Date(item.Date).getFullYear();
                return objYear === currentYear - 1;
            });
            let CurrentMonth = {
                Type: 'Yearly',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }
            let PreviousMonth = {
                Type: 'Yearly',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }

            for (let i = 0; i < currentMonthData.length; i++) {
                let data = currentMonthData[i];
                if (data.Type === 'Forecast') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    CurrentMonth.ForecastIncome += totalIncome;
                    CurrentMonth.ForecastBurnrate += totalBurnrate;
                    CurrentMonth.ForecastCashflow += totalCashflow;
                    CurrentMonth.ForecastExpenses += totalExpenses;
                } else if (data.Type === 'Actual') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    const calculateTotal = (data, key) => {
                        return Object.values(data[key]).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    const filteredData = Object.keys(data)
                        .filter(key => !['Type', 'Date', 'Income', 'Costs', 'Expenses', 'Cashflow'].includes(key))
                        .reduce((obj, key) => {
                            obj[key] = calculateTotal(data, key);
                            return obj;
                        }, {});
                    Object.assign(CurrentMonth, filteredData);
                    CurrentMonth.Income += totalIncome;
                    CurrentMonth.Burnrate += totalBurnrate;
                    CurrentMonth.Cashflow += totalCashflow;
                    CurrentMonth.Expenses += totalExpenses;
                }
            }
            for (let i = 0; i < prevMonthData.length; i++) {
                let data = prevMonthData[i];
                if (data.Type === 'Forecast') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    PreviousMonth.ForecastIncome += totalIncome;
                    PreviousMonth.ForecastBurnrate += totalBurnrate;
                    PreviousMonth.ForecastCashflow += totalCashflow;
                    PreviousMonth.ForecastExpenses += totalExpenses;
                } else if (data.Type === 'Actual') {
                    let income = data.Income;
                    let expenses = data.Expenses;
                    let burnrate = data.Burnrate;
                    let cashflow = data.Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    PreviousMonth.Income += totalIncome;
                    PreviousMonth.Burnrate += totalBurnrate;
                    PreviousMonth.Cashflow += totalCashflow;
                    PreviousMonth.Expenses += totalExpenses;
                }
            }
            DataObj.CurrentMonth = CurrentMonth;
            DataObj.PreviousMonth = PreviousMonth;
            DataObj.Date = new Date(currentYear, currentMonthDate);
            DataObj.PreviousDate = new Date(currentYear, currentMonthDate - 1)
            setSelectedFilter(DataObj);
            store.setLoading(false);
        } else {
            window.alert('No Actuals Avavilable');
            store.setLoading(false);
        }
        forceUpdate();
    };
    const handleEntireData = (acts) => {
        store.setLoading(true);
        if (acts) {
            let DataObj = {};
            let data = acts;
            let CurrentMonth = {
                Type: 'Entire',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }
            let PreviousMonth = {
                Type: 'Entire',
                Income: 0,
                Expenses: 0,
                Burnrate: 0,
                Cashflow: 0,
                ForecastIncome: 0,
                ForecastExpenses: 0,
                ForecastCashflow: 0,
                ForecastBurnrate: 0
            }

            for (let i = 0; i < data.length; i++) {
                if (data[i].Type === 'Forecast') {

                    let income = data[i].Income;
                    let expenses = data[i].Expenses;
                    let burnrate = data[i].Burnrate;
                    let cashflow = data[i].Cashflow;

                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    CurrentMonth.ForecastIncome += totalIncome;
                    CurrentMonth.ForecastBurnrate += totalBurnrate;
                    CurrentMonth.ForecastCashflow += totalCashflow;
                    CurrentMonth.ForecastExpenses += totalExpenses;
                } else if (data[i].Type === 'Actual') {
                    let income = data[i].Income;
                    let expenses = data[i].Expenses;
                    let burnrate = data[i].Burnrate;
                    let cashflow = data[i].Cashflow;
                    let totalIncome = 0;
                    if (income) {
                        totalIncome = Object.values(income).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalExpenses = 0;
                    if (expenses) {
                        totalExpenses = Object.values(expenses).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalCashflow = 0;
                    if (cashflow) {
                        totalCashflow = Object.values(cashflow).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    let totalBurnrate = 0;
                    if (burnrate) {
                        totalBurnrate = Object.values(burnrate).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    const calculateTotal = (data, key) => {
                        return Object.values(data[key]).reduce((acc, arr) => {
                            return acc + arr.reduce((acc, obj) => acc + obj.Amount, 0);
                        }, 0);
                    };
                    const filteredData = Object.keys(data[i])
                        .filter(key => !['Type', 'Date', 'Income', 'Costs', 'Expenses', 'Cashflow'].includes(key))
                        .reduce((obj, key) => {
                            // obj[key] = calculateTotal(data, key);
                            return obj;
                        }, {});
                    Object.assign(CurrentMonth, filteredData);
                    CurrentMonth.Income += totalIncome;
                    CurrentMonth.Burnrate += totalBurnrate;
                    CurrentMonth.Cashflow += totalCashflow;
                    CurrentMonth.Expenses += totalExpenses;
                }
            }
            DataObj.CurrentMonth = CurrentMonth;
            DataObj.PreviousMonth = PreviousMonth;
            setSelectedFilter(DataObj);
            store.setLoading(false);
        } else {
            window.alert('No Actuals Available.');
            store.setLoading(false);
        }
        forceUpdate();
    };
    const handleMoveForward = () => {
        let onset = Onset + 1;
        setOnset(Onset + 1);
        let acts = Actuals;
        if (Filter === 'Entire') {
            handleEntireData(acts, onset)
        } else if (Filter === 'Month') {
            handleMonthlyData(acts, onset)
        } else if (Filter === 'Yearly') {
            handleYearlyData(acts)
        }
        forceUpdate();
    };
    const handleMoveBackward = () => {
        let onset = Onset - 1
        setOnset(Onset - 1);
        forceUpdate();
        let acts = Actuals;
        if (Filter === 'Entire') {
            handleEntireData(acts, onset)
        } else if (Filter === 'Month') {
            handleMonthlyData(acts, onset);
        } else if (Filter === 'Yearly') {
            handleYearlyData(acts);
        }
    };
    const formatDate = (date) => {
        if (date === undefined) {
            date = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
        }
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const year = date.getFullYear();
        const month = date.getMonth();

        if (Filter === 'Month') {
            return `${monthNames[month]} ${year}`;
        } else if (Filter === 'Yearly') {
            return year.toString();
        } else {
            return '';
        }
    };
    const openHover = () => {
        setOnHover(true);
        forceUpdate();
    };
    const syncSheetData = async () => {
        store.setLoading(true)
        try {
            let comp = Company;
            if (comp.SheetID && comp.SheetID !== '' && comp.SheetID !== null) {
                let data = {
                    SheetID: comp.SheetID,
                    CompanyID: comp._id,
                    TabName: comp.TabName
                }

                if (data.SheetID === '' || data.SheetID === undefined || !data.SheetID) {
                    window.alert('No Spreadsheet data available!');
                    return;
                }
                const userConfirmed = window.confirm('Are you sure you want to proceed? This will remove all your custom-added metrics and replace them with the sheet data.');

                if (userConfirmed) {
                    let syncSheetData = await store.getCompaniesSheetData(data);
                    if (syncSheetData === false) {
                        window.alert('Could not fetch spread sheet data.')
                        store.setLoading(false);
                    } else if (syncSheetData === true) {
                        store.setLoading(false);
                        getCompany()
                    }
                } else {
                    store.setLoading(false);
                    return
                }
            } else {
                window.alert('No sheet data available for this company.');
                store.setLoading(false);
            }
            forceUpdate();
        } catch (e) {
            console.log(e);
            store.setLoading(false)
            return false;
        }
    }
    const closeHover = () => {
        setOnHover(false);
        forceUpdate();
    };
    const changeFilter = (e) => {
        let filter = e.currentTarget.id;
        let acts = Actuals;
        let onset = 0;
        setOnset(onset);
        if (filter === 'Entire') {
            setFilter(filter);
            handleEntireData(acts);
        } else if (filter === 'Month') {
            setFilter(filter);
            handleMonthlyData(acts, onset);
        } else if (filter === 'Yearly') {
            setFilter(filter);
            handleYearlyData(acts, onset);
        }
    };
    const handleToggle = () => {
        setIsToggled(!isToggled);
    };
    const income = SelectedFilter.CurrentMonth.Income;
    const expenses = SelectedFilter.CurrentMonth.Expenses;

    const profit = income - expenses;

    function generateReport() {
        setReportGenerated(true);
        forceUpdate();
    };
    const openLabelClicked = () => {
        setIsLabelOn(true);
        forceUpdate();
    };
    const closeLabelClicked = () => {
        setIsLabelOn(false);
        forceUpdate();
    };
    const legendClicked = (e) => {
        let legends = [...LegendsFilter];
        let id = e.currentTarget.id;

        if (legends.includes(id)) {
            legends = legends.filter((legendId) => legendId !== id);
        } else {
            legends.push(id);
        }
        setLegendsFilter(legends);
        console.log(legends);
        forceUpdate();
    };
    if (mobile()) {
        return (
            <View >
            </View>
        );
    } else {
        return (
            <div className='OverViewPage'>
                <div className='income-loss-container'>
                    <div style={{ width: '30%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className='ProfitLossContrainer' style={{ backgroundColor: profit > 0 ? '#213038' : '#A62626' }} onMouseEnter={openLabelClicked} onMouseLeave={closeLabelClicked}>
                            <p>{profit >= 0 ? 'R ' : '-R '} {Math.abs(profit).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            {(IsLabelOn) ? <p className='labelPlaceholder'>{profit > 0 ? 'Profit' : 'Loss'}</p> : null}
                        </div>
                    </div>
                    {/* <button onClick={syncSheetData} className='syncBtn'>Sync Sheet data</button> */}
                    <div className='period-section' onMouseEnter={openHover} onMouseLeave={closeHover}>
                        <p>{Filter}</p>
                        {(OnHover) ?
                            <>
                                <div className='period-pointer' />
                                <div className='period-container'>
                                    <div className='period-container-options' id='Month' style={{ backgroundColor: Filter === 'Month' ? '#D5922D' : '' }} onClick={changeFilter}>Month</div>
                                    <div className='period-container-options' id='Yearly' style={{ backgroundColor: Filter === 'Yearly' ? '#D5922D' : '' }} onClick={changeFilter}>Yearly</div>
                                    <div className='period-container-options' id='Entire' style={{ backgroundColor: Filter === 'Entire' ? '#D5922D' : '' }} onClick={changeFilter}>Entire</div>
                                </div>
                            </> : null}
                    </div>
                </div>
                <div className='overviewCharts'>
                    <div className='newHorizontalChartSection'>
                        <div className='filteredDateandTotalsSect' style={{ justifyContent: Filter === 'Entire' ? 'center' : 'space-between' }}>
                            {(Filter !== 'Entire') ?
                                <img src={LeftArrow} alt='' style={{ cursor: 'pointer' }} onClick={handleMoveBackward} />
                                : null}
                            <p>Revenue {formatDate(SelectedFilter.Date)} - <span style={{ color: '#D4912D' }}>
                                R{SelectedFilter.CurrentMonth.Income !== undefined ? SelectedFilter.CurrentMonth.Income.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                            </span></p>
                            {(Filter !== 'Entire') ?
                                <img src={RightArrow} alt='' style={{ cursor: 'pointer' }} onClick={handleMoveForward} />
                                : null}
                        </div>
                        <div className='horizontalChartSect' ref={graphSectionRef}>
                            <CashflowGraph Actual={SelectedFilter} currentAct={SelectedFilter.CurrentMonth.Income} currentFore={SelectedFilter.CurrentMonth.ForecastIncome} previousAct={SelectedFilter.PreviousMonth.Income} previousFore={SelectedFilter.PreviousMonth.ForecastIncome} width={dimensions.width} height={dimensions.height - 15} date={formatDate(SelectedFilter.Date)} prevDate={formatDate(SelectedFilter.PreviousDate)} type='Income' />
                        </div>
                        <div className='legendsSection'>
                            <div className='actual-Label' /><p>Actual</p>
                            <div className='forecast-Label' /><p>Forecast</p>
                            <div className='difference-Label' /><p>Difference</p>
                        </div>
                    </div>
                    <div className='newHorizontalChartSection'>
                        <div className='filteredDateandTotalsSect' style={{ justifyContent: Filter === 'Entire' ? 'center' : 'space-between' }}>
                            {(Filter !== 'Entire') ?
                                <img src={LeftArrow} alt='' style={{ cursor: 'pointer' }} onClick={handleMoveBackward} />
                                : null}
                            <p>Expenses {formatDate(SelectedFilter.Date)} - <span style={{ color: '#D4912D' }}>
                                R{SelectedFilter.CurrentMonth.Expenses !== undefined ? SelectedFilter.CurrentMonth.Expenses.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                            </span></p>
                            {(Filter !== 'Entire') ?
                                <img src={RightArrow} alt='' style={{ cursor: 'pointer' }} onClick={handleMoveForward} />
                                : null}
                        </div>
                        <div className='horizontalChartSect' ref={graphSectionRef}>
                            <CashflowGraph Actual={SelectedFilter} currentAct={SelectedFilter.CurrentMonth.Expenses} currentFore={SelectedFilter.CurrentMonth.ForecastExpenses} previousAct={SelectedFilter.PreviousMonth.Expenses} previousFore={SelectedFilter.PreviousMonth.ForecastExpenses} width={dimensions.width} height={dimensions.height - 15} date={formatDate(SelectedFilter.Date)} prevDate={formatDate(SelectedFilter.PreviousDate)} type='Expenses' />
                        </div>
                        <div className='legendsSection'>
                            <div className='actual-Label' /><p>Actual</p>
                            <div className='forecast-Label' /><p>Forecast</p>
                            <div className='difference-Label' /><p>Difference</p>
                        </div>
                    </div>
                    <div className='newOverViewcard'>
                        <div className='runwaySection'>
                            <div className='gaugeContainer'>
                                <RunwayGauge style={{ marginTop: '6%', width: '165px' }} width={200} height={200} value={runwayData} />
                                <p>Runway: {runwayData || 0} Months</p>
                            </div>
                            <div className='forecastToogleSwitch'>
                                <p>Forecast</p>
                                <label className="switch">
                                    <input type="checkbox" checked={isToggled} onChange={handleToggle} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className='chartContainer' ref={overViewSectionref}>
                            <BurnRateGraph width={overViewDimensions.width} height={overViewDimensions.height} data={chartData} isForecast={isToggled} exclude={LegendsFilter} />
                        </div>
                        <div className='availLegendsContainer'>
                            <div className='income-Label' style={{ backgroundColor: LegendsFilter.includes('Income') ? 'grey' : '', cursor: 'pointer' }} onClick={legendClicked} id='Income' />
                            <p style={{ marginRight: '5%', WebkitTextStroke: LegendsFilter.includes('Income') ? '0px #213038' : 'none', textDecoration: LegendsFilter.includes('Income') ? 'line-through' : 'none', cursor: 'pointer' }} id='Income' onClick={legendClicked}>Revenue</p>
                            <div className='expense-Label' style={{ backgroundColor: LegendsFilter.includes('Expenses') ? 'grey' : '', cursor: 'pointer' }} onClick={legendClicked} id='Expenses' />
                            <p style={{ marginRight: '5%', WebkitTextStroke: LegendsFilter.includes('Expenses') ? '0px #213038' : 'none', textDecoration: LegendsFilter.includes('Expenses') ? 'line-through' : 'none', cursor: 'pointer' }} id='Expenses' onClick={legendClicked}>Expenses</p>
                            <div className='burnrate-Label' style={{ backgroundColor: LegendsFilter.includes('Burnrate') ? 'grey' : '', cursor: 'pointer' }} onClick={legendClicked} id='Burnrate' />
                            <p style={{ marginRight: '5%', WebkitTextStroke: LegendsFilter.includes('Burnrate') ? '0px #213038' : 'none', textDecoration: LegendsFilter.includes('Burnrate') ? 'line-through' : 'none', cursor: 'pointer' }} id='Burnrate' onClick={legendClicked}>Burnrate</p>
                            <div className='cashflow-Label' style={{ backgroundColor: LegendsFilter.includes('Cashflow') ? 'grey' : '', cursor: 'pointer' }} onClick={legendClicked} id='Cashflow' />
                            <p style={{ marginRight: '5%', WebkitTextStroke: LegendsFilter.includes('Cashflow') ? '0px #213038' : 'none', textDecoration: LegendsFilter.includes('Cashflow') ? 'line-through' : 'none', cursor: 'pointer' }} id='Cashflow' onClick={legendClicked}>Cashflow</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});

export default Report;
